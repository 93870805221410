export default function LoadingSimple(): JSX.Element {
	return (
		<div>
			<div
				className="d-flex align-items-center justify-content-center"
				style={{ height: '100%' }}
			>
				<div
					className="spinner-border"
					style={{ width: '5rem', height: '5rem' }}
					role="status"
				>
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		</div>
	)
}
