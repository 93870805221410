import { useState } from 'react'

import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import useQuery from 'hooks/useQuery'
import PictureEditor from 'pages/ProductSalePictureEditor/components/PictureEditor/PictureEditor'

export default function ProductSaleDetailPictureEditorPage() {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const query = useQuery()

	function render() {
		const id: string | null = query.get('id')
		if (!id) return <h1>404</h1>
		return <PictureEditor id={id} setIsLoading={setIsLoading} />
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				{render()}
			</div>
		</main>
	)
}
