import axios from 'axios'

import CollectionApi from 'interfaces/CollectionApi'

export async function call(id: number): Promise<CollectionApi | null> {
	try {
		const response: Response = await axios.get('/collection/' + id)
		return response.data.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: CollectionApi
}
