import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Navigate, Route, Routes } from 'react-router-dom'

import { configAxios } from 'axiosConfig'
import AuctionDetailPage from 'pages/AuctionDetail/Index'
import AuctionSearchPage from 'pages/AuctionSearch/Index'
import CollectionDetailPage from 'pages/CollectionDetail/Index'
import CollectionSearchPage from 'pages/CollectionSearch/Index'
import Login from 'pages/Login/Index'
import NotFound from 'pages/NotFound/Index'
import ProductSaleDetailPage from 'pages/ProductSaleDetail/Index'
import ProductSaleDetailPictureEditor from 'pages/ProductSalePictureEditor/Index'
import ProductSaleSearchPage from 'pages/ProductSaleSearch/Index'
import UserDetailPage from 'pages/UserDetail/Index'
import UserSearchPage from 'pages/UserSearch/Index'
import reportWebVitals from 'reportWebVitals'
import 'styles/main.scss'

configAxios()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Navigate replace to="/login" />} />
			<Route path="/login" element={<Login />} />
			<Route path="/product/search" element={<ProductSaleSearchPage />} />
			<Route path="/product/detail" element={<ProductSaleDetailPage />} />
			<Route
				path="/product/detail/picture-editor"
				element={<ProductSaleDetailPictureEditor />}
			/>
			<Route path="/auction/search" element={<AuctionSearchPage />} />
			<Route path="/auction/detail" element={<AuctionDetailPage />} />
			<Route path="/auction/new" element={<AuctionDetailPage />} />
			<Route path="/collection/search" element={<CollectionSearchPage />} />
			<Route path="/collection/new" element={<CollectionDetailPage />} />
			<Route path="/collection/detail" element={<CollectionDetailPage />} />
			<Route path="/user/search" element={<UserSearchPage />} />
			<Route path="/user/new" element={<UserDetailPage />} />
			<Route path="/user/detail" element={<UserDetailPage />} />
			<Route path="/*" element={<NotFound />} />
		</Routes>
	</BrowserRouter>
)

reportWebVitals()
