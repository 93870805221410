import axios from 'axios'

import { APIURL } from 'constants/urls'

export async function apiCall(input: RequestBody): Promise<void | null> {
	const url = APIURL + '/link-sale/position'
	try {
		await axios.put(url, input)
		return
	} catch (e: unknown) {
		return null
	}
}

export type RequestBody = {
	linkSaleId: number
	position: number
}

export interface Response {}

export interface ResponseData {
	id: number
	position: number
}
