import ProductSaleList from 'classes/ProductSaleList'
import { showToast } from 'components/Toast/Toast'
import clone from 'functions/clone'

interface Props {
	productSaleList: ProductSaleList
	setProductSaleList: (input: ProductSaleList) => void
	setIsLoading: (input: boolean) => void
	transferValuesToProductSaleList: () => void
}

export default function ActionButtons(props: Props) {
	function handleCancel(): void {
		window.history.back()
	}

	async function handleSave(): Promise<void> {
		props.setIsLoading(true)
		try {
			props.transferValuesToProductSaleList()
			for (const i of props.productSaleList.list) {
				await i.writeToApi()
			}
			props.setProductSaleList(clone(props.productSaleList))
			showToast(200, 'Produkte wurden erfolgreich gespeichert')
		} catch (e) {
			showToast(400)
		} finally {
			props.setIsLoading(false)
		}
	}

	return (
		<div className="mb-4 d-flex justify-content-between">
			<div>
				<button
					type="button"
					className="btn btn-primary  btn-sm"
					style={{ marginRight: '10px' }}
					onClick={handleSave}
				>
					Speichern
				</button>

				<button
					type="button"
					className="btn btn-outline-primary  btn-sm"
					onClick={handleCancel}
				>
					Abbrechen
				</button>
			</div>
		</div>
	)
}
