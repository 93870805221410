import { useEffect } from 'react'

import LinkSale from 'classes/LinkSale'
import LinkSaleList from 'classes/LinkSaleList'
import { showToast } from 'components/Toast/Toast'
import * as COLOR from 'constants/COLOR'
import clone from 'functions/clone'

interface Props {
	linkSaleList: LinkSaleList
	setLinkSaleList: (input: LinkSaleList) => void
	selectedLink: LinkSale | null
	setSelectedLink: (input: LinkSale | null) => void
}

export default function Gallery(props: Props) {
	function handleChangeName(e: React.ChangeEvent<HTMLInputElement>): void {
		const value: string = e.target.value
		props.linkSaleList.changeName(value)
		props.setLinkSaleList(clone(props.linkSaleList))
	}

	useEffect(() => {
		console.log('linkSaleList')
	}, [props.linkSaleList])

	function handleClickNewLink() {
		props.linkSaleList.addNew()
		props.setLinkSaleList(clone(props.linkSaleList))
	}

	return (
		<div>
			<button
				type="button"
				className="btn btn-sm btn-outline-primary mb-3"
				onClick={handleClickNewLink}
			>
				Neuen Link hinzufügen
			</button>
			<div
				className="d-flex flex-wrap justify-content-center"
				style={{ gap: '15px', backgroundColor: '#ddd', padding: '20px 20px' }}
			>
				{props.linkSaleList.list.map((link: LinkSale, i: number) => {
					return (
						<div
							key={i}
							className="card"
							style={{
								width: '15rem',
								backgroundColor:
									props.selectedLink?.id === link.id
										? COLOR.PRIMARY(0.1)
										: undefined,
							}}
						>
							<img
								src={link.getUrl()}
								className="card-img-top"
								style={{
									height: '200px',
									objectFit: 'contain',
									backgroundColor: 'black',
									opacity: link.deletedAt ? '0.5' : undefined,
								}}
							/>
							<div className="card-body">
								<div className="mb-2 d-flex justify-content-between">
									<div>
										<span className="badge bg-secondary">{link.id}</span>
									</div>
									<div>
										<span style={{ textAlign: 'right' }}>
											<span style={{ color: 'grey', fontSize: '0.8em' }}>
												Position:
											</span>{' '}
											<strong>{link.position}</strong>
										</span>
									</div>
								</div>
								{link.id ? (
									<input
										type="text"
										className="form-control form-control-sm mb-2"
										disabled
										placeholder={link.name}
										title={link.name}
									/>
								) : (
									<input
										type="text"
										className="form-control form-control-sm mb-2"
										onChange={handleChangeName}
									/>
								)}
								<Buttons {...props} link={link} />
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

function Buttons(props: Props & { link: LinkSale }) {
	function handleClickEdit(link: LinkSale) {
		if (link.id === props.selectedLink?.id) {
			props.setSelectedLink(null)
			return
		}
		props.setSelectedLink(link)
	}

	async function handleClickDelete(link: LinkSale) {
		if (link.name === '') {
			props.linkSaleList.list = props.linkSaleList.list.filter((e) => e.id !== link.id)
			props.setLinkSaleList(clone(props.linkSaleList))
		} else {
			await link.delete()
			props.setLinkSaleList(clone(props.linkSaleList))
			showToast(200, 'Bild wurde erfolgreich als gelöscht markiert.')
		}
	}

	async function handleClickRestore(link: LinkSale) {
		await link.restore()
		props.setLinkSaleList(clone(props.linkSaleList))
		showToast(200, 'Bild wurde erfolgreich wiederhergestellt.')
	}

	async function handleClickSave() {
		await props.linkSaleList.save()
		props.setLinkSaleList(clone(props.linkSaleList))
	}

	if (props.link.id) {
		return (
			<div className="btn-group" role="group" aria-label="Basic example">
				{!props.link.deletedAt && (
					<button
						type="button"
						className="btn btn-sm btn-outline-primary"
						onClick={() => handleClickEdit(props.link)}
					>
						Editieren
					</button>
				)}
				{!props.link.deletedAt ? (
					<button
						type="button"
						className="btn btn-sm btn-outline-danger"
						onClick={() => handleClickDelete(props.link)}
					>
						Löschen
					</button>
				) : (
					<button
						type="button"
						className="btn btn-sm btn-outline-success"
						onClick={() => handleClickRestore(props.link)}
					>
						Wiederherstellen
					</button>
				)}
			</div>
		)
	}

	return (
		<div className="btn-group" role="group" aria-label="Basic example">
			<button
				type="button"
				className="btn btn-sm btn-outline-primary"
				onClick={handleClickSave}
			>
				Speichern
			</button>
		</div>
	)
}
