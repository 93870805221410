import * as apiGet from 'api/AuctionList/AuctionList'
import { Option } from 'pages/ProductSaleDetail/components/Select'

import AuctionListApi from '../interfaces/AuctionListApi'
import Auction from './Auction'

export default class AuctionList {
	public all: Auction[] = []
	public searched: Auction[] = []
	public filtered: Option[] = []
	public list: Auction[] = []
	public count: number = 0

	public constructor(input?: AuctionListApi) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public async initialize() {
		await this.readFromApi()
	}

	public selectAll(): void {
		for (const i of this.all) {
			i.selected = true
		}
	}

	public unselectAll(): void {
		for (const i of this.all) {
			i.selected = false
		}
	}

	public getCountOfSelected(): number {
		const check: Auction[] = this.all.filter((e: Auction) => {
			if (e.selected === true) {
				return e
			}
		})
		return check.length
	}

	public toggleSelectById(id: number): void {
		const auction: Auction | undefined = this.getById(id)
		if (auction) {
			auction.selected = !auction.selected
		}
	}

	public getFirstSelected(): Auction | undefined {
		return this.all.find((e: Auction) => {
			return e.selected
		})
	}

	public setSearched(input: string): void {
		const auctions: Auction[] = this.all.filter((e: Auction) => {
			if (e.name.toLowerCase().includes(input.toLowerCase())) {
				return e
			}
		})
		this.searched = auctions
	}

	public async search(input: string): Promise<void> {
		const auctions: Option[] = this.filtered.filter((e: Option) => {
			if (e.value.toLowerCase().includes(input.toLowerCase())) {
				return e
			}
		})
		this.filtered = auctions
	}

	public async readFromApi(): Promise<void> {
		const response: AuctionListApi | null = await apiGet.call()
		if (response) {
			this.mapApiToThis(response)
		}
	}

	public async deleteSelectedFromApi(): Promise<void> {
		const auction: Auction | undefined = this.getFirstSelected()
		if (auction) {
			await auction.apiDelete()
			this.removeFromAll(auction)
		}
	}

	public async exportSelectedFromApi(): Promise<void> {
		const auction: Auction | undefined = this.getFirstSelected()
		if (auction) {
			await auction.apiExport()
		}
	}

	public removeFromAll(auction: Auction): void {
		this.all = this.all.filter((e: Auction) => {
			if (e.id !== auction.id) {
				return e
			}
		})
	}

	public getById(id: number | null): Auction | undefined {
		if (id === null) return undefined
		return this.all.find((e: Auction) => {
			return e.id === id
		})
	}

	private mapApiToThis(input: AuctionListApi): void {
		this.all = []
		this.searched = []
		this.list = []
		this.count = input.count
		for (const item of input.data) {
			if (!item.delete) {
				const auction = new Auction(item)
				this.all.push(auction)
				this.searched.push(auction)
				this.list.push(auction)
			}
		}
	}
}
