import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import User from 'classes/User'
import { showToast } from 'components/Toast/Toast'

export default function LoginPage() {
	const [user, _setUser] = useState<User>(new User())
	const navigate = useNavigate()

	useEffect(() => {
		tryToLogin()
	}, [])

	function tryToLogin(): void {
		const token: string | null = localStorage.getItem('token')
		if (token) {
			navigate('/product/search')
		}
	}

	function changeName(e: React.ChangeEvent<HTMLInputElement>): void {
		const value: string = e.target.value
		user.username = value
	}

	function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {
		switch (e.key) {
			case 'Enter':
				login()
				break
		}
	}

	function changePassword(e: React.ChangeEvent<HTMLInputElement>): void {
		const value: string = e.target.value
		user.password = value
	}

	async function login(): Promise<void> {
		const okay: boolean = await user.login()
		if (okay) {
			navigate('/product/search')
		} else {
			showToast(400, 'Login ist fehlgeschlagen')
		}
	}

	return (
		<div className="d-flex justify-content-center mt-5">
			<div
				style={{
					width: '400px',
					backgroundColor: 'white',
					gap: '20px',
					padding: '40px',
					borderRadius: '20px',
				}}
				className="d-flex flex-column align-items-center"
			>
				<img src="/img/Logo_RJ.png" style={{ width: '180px', marginTop: '20px' }} />
				<hr style={{ width: '100%' }} />
				<div className="form-floating" style={{ width: '100%' }}>
					<input
						type="email"
						className="form-control"
						id="floatingInput"
						defaultValue={user.username}
						onKeyDown={handleKeyDown}
						onChange={changeName}
					/>
					<label htmlFor="floatingInput">Benutzername</label>
				</div>
				<div className="form-floating" style={{ width: '100%' }}>
					<input
						type="password"
						className="form-control"
						id="floatingPassword"
						onKeyDown={handleKeyDown}
						defaultValue={user.password}
						onChange={changePassword}
					/>
					<label htmlFor="floatingPassword">Password</label>
				</div>
				<button
					onClick={login}
					type="button"
					className="btn btn-primary"
					style={{ width: '100%' }}
				>
					Einloggen
				</button>
			</div>
		</div>
	)
}
