import User from 'classes/User'
import UserList from 'classes/UserList'
import Search from 'pages/ProductSaleSearch/classes/Search'

interface Props {
	search: Search
	setSearch: (input: Search) => void
	handleSearch: () => Promise<void>
	userList: UserList
}

export default function UserSelectComponent(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		const value: number = Number(e.target.value)
		if (value === 0) {
			props.search.user.value = null
		} else {
			props.search.user.value = Number(value)
		}
		props.handleSearch()
	}

	function getValue() {
		if (!props.search.user.value) {
			return '0'
		}
		return String(props.search.user.value)
	}

	return (
		<select className="form-select" onChange={handleChange} value={getValue()}>
			<option value={0}>Alle Einlieferer</option>
			{props.userList.all.map((e: User, i: number) => {
				return (
					<option value={e.id} key={i}>
						{e.id + ' – ' + e.firstname + ' ' + e.lastname}
					</option>
				)
			})}
		</select>
	)
}
