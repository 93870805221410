import * as apiGet from 'api/Collection/GetCollectionById'
import * as apiPost from 'api/Collection/PostCollection'
import * as apiPut from 'api/Collection/PutCollectionById'
import User from 'classes/User'
import CollectionApi from 'interfaces/CollectionApi'

export type propsString = 'name'

export default class Collection {
	public id: number = 0
	public creator: User = new User()
	public name: string = ''
	public users: User[] = []
	public products: string[] = []
	public createdAt: Date = new Date()
	public updatedAt: Date = new Date()

	public constructor(input?: CollectionApi) {
		if (input) this.mapFromApi(input)
	}

	private mapToApi(): CollectionApi {
		return {
			id: this.id,
			name: this.name,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			userList: this.users.map((e) => e.mapToApi()),
			creator: this.creator.mapToApi(),
			products: this.products,
		}
	}

	public addUser(input: User) {
		if (this.users.find((e) => e.id === input.id)) return
		this.users.push(input)
	}

	public removeUser(input: User) {
		this.users = this.users.filter((e) => e.id !== input.id)
	}

	public async save(): Promise<{
		status: number
		id: number | null
	}> {
		if (this.id) {
			const response: apiPut.Response = await this.apiPut()
			return {
				status: response.status,
				id: null,
			}
		}
		const response: apiPost.Response = await this.apiPost()
		return {
			status: response.status,
			id: response.data?.data ?? null,
		}
	}

	public async apiGet(id: number): Promise<void> {
		const response: CollectionApi | null = await apiGet.call(id)
		if (response) {
			this.mapFromApi(response)
		}
	}

	public async apiPut(): Promise<apiPut.Response> {
		return await apiPut.call(this.mapToApi())
	}

	public async apiPost(): Promise<apiPost.Response> {
		return await apiPost.call(this.mapToApi())
	}

	private mapFromApi(input: CollectionApi): void {
		this.id = input.id
		this.name = input.name
		this.creator.mapApiToThis(input.creator)
		this.users = input.userList.map((e) => new User(e))
		this.products = input.products
		this.createdAt = input.createdAt
		this.updatedAt = input.updatedAt
	}
}
