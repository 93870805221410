import { useState } from 'react'

import LinkSale, { ImageSizeKeys } from 'classes/LinkSale'
import FileUpload from 'components/FileUpload'
import { showToast } from 'components/Toast/Toast'
import clone from 'functions/clone'

interface Props {
	link: LinkSale
	setLink: (input: LinkSale) => void
}

class Preview {
	thumbnail: string | null = null
	small: string | null = null
	big: string | null = null
	original: string | null = null
}

export default function Detail(props: Props) {
	const [selectedImage, setSelectedImage] = useState<ImageSizeKeys | null>(null)
	const [preview, setPreview] = useState<Preview>(new Preview())

	function handleClick(key: ImageSizeKeys) {
		if (key === selectedImage) {
			setSelectedImage(null)
			return
		}
		setSelectedImage(key)
	}

	async function handleUpdatePosition() {
		await props.link.putToApiPosition()
		showToast(200, 'Position wurde erfolgreich gespeichert.')
	}

	function changePosition(e: React.ChangeEvent<HTMLInputElement>): void {
		const value: number = Number(e.target.value)
		if (!value) return
		props.link.position = value
		props.setLink(clone(props.link))
	}

	function createHandleSetPreview(key: ImageSizeKeys) {
		return (input: string | null) => {
			preview[key] = input
			setPreview(clone(preview))
		}
	}

	function renderInfo(key: ImageSizeKeys, description: string) {
		return (
			<div className="d-flex" style={{ gap: '15px' }}>
				{renderImg(key)}
				<div
					style={{ width: '100%', borderTop: '1px solid #ddd' }}
					className="d-flex flex-column justify-content-between"
				>
					<div>
						<div
							style={{
								fontWeight: 'bold',
								fontSize: '1.2em',
							}}
						>
							{key}
							<span> {description}</span>
						</div>
						<div style={{ fontSize: '0.8em', color: 'grey' }}>
							{props.link.getUrl(key)}
						</div>
					</div>

					<FileUpload
						id={props.link.id ?? 0}
						size={getSizeFromImageSizeKeys(key)}
						preview={preview[key]}
						setPreview={createHandleSetPreview(key)}
					/>
				</div>
			</div>
		)
	}

	function renderImg(key: ImageSizeKeys) {
		return (
			<img
				src={preview[key] ?? props.link.getUrl(key) ?? ''}
				style={{
					minHeight: '100px',
					maxHeight: '100px',
					minWidth: '150px',
					maxWidth: '150px',
					objectFit: 'contain',
					backgroundColor: 'black',
					border: selectedImage === key ? '5px solid blue' : undefined,
				}}
				onClick={() => handleClick(key)}
			/>
		)
	}

	function renderPosition() {
		return (
			<>
				<h6 style={{ color: 'grey', paddingTop: '5px' }}>Position</h6>
				<div className="input-group mb-3">
					<input
						type="text"
						className="form-control"
						defaultValue={props.link.position}
						onChange={changePosition}
					/>
					<button
						className="btn btn-outline-primary"
						type="button"
						onClick={() => handleUpdatePosition()}
					>
						Speichern
					</button>
				</div>
			</>
		)
	}

	return (
		<div>
			<h4 className="mt-4">Detail</h4>
			<div>
				{renderPosition()}
				<h6 style={{ color: 'grey', paddingTop: '5px' }}>Einzelbilder</h6>
				<div className="d-flex flex-column" style={{ gap: '15px' }}>
					{renderInfo('original', '')}
					{renderInfo('big', '(1600x900)')}
					{renderInfo('small', '(350)')}
					{renderInfo('thumbnail', '(100x100)')}
				</div>
			</div>
		</div>
	)
}

export function getSizeFromImageSizeKeys(sizeKey: string | undefined) {
	switch (sizeKey) {
		case 'thumbnail':
			return '100x100'
		case 'small':
			return '350'
		case 'big':
			return '1600x900'
		case 'original':
			return 'original'
		default:
			return '350'
	}
}
