import { ChangeEvent, useEffect, useState } from 'react'

import * as apiPost from 'api/LinkSale/PostLinkSalePicture'

import { showToast } from './Toast/Toast'

interface Props {
	id: number
	size: string
	preview: string | null
	setPreview: (input: string) => void
}

export default function FileUpload(props: Props) {
	const [message, setMessage] = useState<string>('')
	const [file, setFile] = useState<File | null>(null)

	useEffect(() => {
		return () => {
			if (props.preview) {
				URL.revokeObjectURL(props.preview)
			}
		}
	}, [props.preview])

	const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const selectedFile = e.target.files[0]
			setFile(selectedFile)

			const previewUrl = URL.createObjectURL(selectedFile)
			props.setPreview(previewUrl)
		}
	}

	const onFileUpload = async () => {
		if (!file) {
			setMessage('Bitte zuerst ein File auswählen.')
			return
		}

		const formData = new FormData()
		formData.append('file', file)

		try {
			await apiPost.call(formData, props.id, props.size)
			showToast(200, 'Bild wurde erfolgreich hochgeladen und ersetzt.')
		} catch (error) {
			showToast(400, 'Upload hat nicht geklappt.')
		}
	}

	return (
		<div>
			{message && <p style={{ color: 'red' }}>{message}</p>}
			<div className="d-flex justify-content-between">
				<input type="file" onChange={onFileChange} accept="image/jpeg" />
				<button className="btn btn-sm btn-outline-primary" onClick={onFileUpload}>
					Bild Uploaden
				</button>
			</div>
		</div>
	)
}
