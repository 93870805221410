import * as apiGetAll from 'api/CollectionList/GetCollectionListAll'
import Collection from 'classes/Collection'
import CollectionListApi from 'interfaces/CollectionListApi'

export default class CollectionList {
	public all: Collection[] = []
	public searched: Collection[] = []
	public count: number = 0

	public constructor(input?: CollectionListApi) {
		if (input) {
			this.mapFromApi(input)
		}
	}

	public async initialize() {
		await this.getFromApi()
	}

	public async getFromApi(): Promise<void> {
		const response: apiGetAll.ResponseData | null = await apiGetAll.call()
		if (response) {
			this.mapFromApi(response)
		}
	}

	private mapFromApi(input: CollectionListApi): void {
		this.all = []
		this.count = input.count
		for (const item of input.all) {
			const collection = new Collection(item)
			this.all.push(collection)
		}
	}

	public setSearched(input: string): void {
		const items: Collection[] = this.all.filter((e: Collection) => {
			if (e.name.toLowerCase().includes(input.toLowerCase())) {
				return e
			}
		})
		this.searched = items
	}
}
