import axios from 'axios'

export async function call(): Promise<any | null> {
	try {
		const response: Response = await axios.get('/product-sale-list/export')
		return response
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Request = {
	productSaleIds: string[] | null
	auctionId: number | null
}

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: File
}
