import { useState } from 'react'
import { useEffect } from 'react'

import CategoryList from 'classes/CategoryList'
import Product from 'classes/Product'
import ProductSale from 'classes/ProductSale'
import ProductSetList from 'classes/ProductSetList'
import ImageWithFallback from 'components/ImageWithFallback'
import { defaultImagePath } from 'constants/defaultImagePath'
import clone from 'functions/clone'
import CheckboxesProductSet from 'pages/ProductSaleDetail/components/CheckboxesProductSet'
import InputNumber from 'pages/ProductSaleDetail/components/InputNumber'
import InputString from 'pages/ProductSaleDetail/components/InputString'
import Styles from 'types/Style'

interface Props {
	productSetList: ProductSetList
	setProductSetList: (input: ProductSetList) => void
	categoryList: CategoryList
	productSale: ProductSale
	setProductSale: (input: ProductSale) => void
}

export default function ProductSet(props: Props) {
	const [price, setPrice] = useState<number>(0)
	const [priceProposal, setPricePoposal] = useState<number | undefined>(undefined)
	const [priceProposalKey, setPriceProposalKey] = useState<string | undefined>(undefined)

	useEffect(() => {}, [priceProposalKey])

	function handleApply(): void {
		props.productSale.mapFromProductSetList({
			productSetList: props.productSetList,
			price: price,
			priceProposal: priceProposal,
			priceProposalKey: priceProposalKey,
		})
		props.setProductSale(clone(props.productSale))
	}

	function renderImage(input: Product): JSX.Element {
		return (
			<ImageWithFallback
				fallback={defaultImagePath}
				src={input.image.thumbnail ?? ''}
				style={styles.picture}
			/>
		)
	}

	return (
		<>
			<div className="source-product" style={styles.tabContent}>
				<div
					className="d-flex flex-wrap justify-content-center"
					style={{ gap: '5px', padding: '10px', backgroundColor: 'black' }}
				>
					{props.productSetList.list.map((e: Product, i: number) => {
						return renderImage(e)
					})}
				</div>
				<div className="row mt-2">
					<div className="col-8">
						<InputString
							label="Kategorie"
							value={props.categoryList.getFullNameById(
								props.productSetList.list[0].category ?? 0
							)}
							disabled={true}
						/>
					</div>
					<div className="col-4">
						<InputString
							label="Sortierung"
							value={props.productSetList.getSortOrder()}
							color={'#cce5ff'}
							disabled={true}
						/>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-6">
						<InputString
							label="Zumstein-Nr"
							value={props.productSetList.getZnr()}
							disabled={true}
						/>
					</div>
					<div className="col-6">
						<InputString
							label="Zumstein-Nr Nummer"
							value={props.productSetList.getZnrNumber()}
							color={'#ffdede'}
							disabled={true}
						/>
					</div>
					<div className="row mt-2">
						<InputString
							label="Titel (deutsch)"
							value={props.productSetList.getTitle()}
							disabled={true}
						/>
					</div>
					<div className="row mt-2">
						<InputString
							label="Jahr"
							value={props.productSetList.getYear()}
							disabled={true}
						/>
					</div>
					<div className="row mt-2">
						<InputString
							label="Beschrieb"
							value={props.productSetList.getDescription()}
							disabled={true}
						/>
					</div>
					<div className="row mt-4">
						<div className="col-12">
							<CheckboxesProductSet
								productSetList={props.productSetList}
								priceProposal={priceProposal}
								setPriceProposal={setPricePoposal}
								setPriceProposalKey={setPriceProposalKey}
								price={price}
								setPrice={setPrice}
							/>
						</div>
					</div>
					<div className="row mt-2">
						<InputNumber
							label="Verkaufspreis"
							defaultValue={price}
							changeValue={setPrice}
						/>
					</div>
					<div className="mt-4">
						<button type="button" onClick={handleApply} className="btn btn-primary">
							Übernehmen
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

const styles: Styles = {
	tabContent: { backgroundColor: '#eaeaea', padding: '10px' },
	picture: {
		objectFit: 'contain',
		backgroundColor: 'black',
		width: '100px',
		height: '100px',
	},
}
