import * as apiDelete from 'api/Auction/DeleteAuctionById'
import * as apiGet from 'api/Auction/GetAuctionById'
import * as apiPost from 'api/Auction/PostAuction'
import * as apiPut from 'api/Auction/PutAuction'
import * as apiExport from 'api/ProductSaleList/GetProductSaleListExport'
import AuctionHighlights from 'classes/AuctionHighlight'
import ProductSale from 'classes/ProductSale'
import getSqlDate from 'functions/getSqlDate'
import AuctionApi from 'interfaces/AuctionApi'

export default class Auction {
	id: number = 0
	name: string = ''
	start: Date | null = null
	end: Date | null = null
	description: string | null = null
	active: boolean = false
	createdAt: Date = new Date()
	updatedAt: Date | null = null
	delete: boolean = false
	deletedAt: Date | null = null
	selected: boolean = false
	highlights: AuctionHighlights = new AuctionHighlights()

	public constructor(input?: AuctionApi) {
		if (input) {
			this.mapToThis(input)
		}
	}

	public getDate(input: 'start' | 'end' | 'createdAt' | 'updatedAt'): string {
		if (this[input]) {
			const day = this[input]?.getUTCDate()
			const month = this[input]?.getUTCMonth()
			const year = this[input]?.getUTCFullYear()
			return (
				this.makeZeroBeforeSingleNumber(day) +
				'.' +
				this.makeZeroBeforeSingleNumber(month) +
				'.' +
				year
			)
		}
		return '–'
	}

	public async apiGet(id: number): Promise<void> {
		const response: AuctionApi | null = await apiGet.call(id)
		if (response) {
			this.mapToThis(response)
		}
	}

	public async apiPut(): Promise<void> {
		await apiPut.call(this.id, this.mapToApi())
	}

	public async apiPost(): Promise<number | null> {
		return await apiPost.call(this.mapToApi())
	}

	public async apiDelete(): Promise<void> {
		if (this.id) {
			await apiDelete.call(this.id)
		}
	}

	public async apiExport(): Promise<void> {
		if (this.id) {
			const response = await apiExport.call()
		}
	}

	public async save(): Promise<number | null> {
		if (this.id) {
			await this.apiPut()
			return null
		}
		return this.apiPost()
	}

	public mapToApi(): AuctionApi {
		return {
			id: this.id,
			name: this.name,
			start: this.start ? getSqlDate(this.start) : null,
			end: this.end ? getSqlDate(this.end) : null,
			description: this.description,
			active: this.active,
			createdAt: getSqlDate(this.createdAt),
			updatedAt: this.updatedAt ? getSqlDate(this.updatedAt) : null,
			delete: this.delete,
			deletedAt: this.deletedAt ? getSqlDate(this.deletedAt) : null,
			highlights: this.highlights.mapToApi(),
		}
	}

	public getHighlights(): (ProductSale | null)[] {
		const output: (ProductSale | null)[] = []
		output.push(this.highlights[1])
		output.push(this.highlights[2])
		output.push(this.highlights[3])
		return output
	}

	private makeZeroBeforeSingleNumber(input: number | undefined) {
		if (!input) {
			return '–'
		}
		const temp: string = '0' + input
		return temp.slice(-2)
	}

	private mapToThis(input: AuctionApi) {
		this.id = input.id
		this.name = input.name
		this.start = input.start ? new Date(input.start) : null
		this.end = input.end ? new Date(input.end) : null
		this.description = input.description
		this.active = input.active
		this.createdAt = input.createdAt ? new Date(input.createdAt) : new Date()
		this.updatedAt = input.updatedAt ? new Date(input.updatedAt) : null
		this.delete = input.delete
		this.deletedAt = input.deletedAt ? new Date(input.deletedAt) : null
		this.highlights.mapFromApi(input.highlights)
	}
}
