import * as api from 'api/ProductList/GetProductListSetByProductId'
import Product from 'classes/Product'
import { getPriceString } from 'functions/numberString'

export default class ProductSetList {
	public list: Product[] = []
	public count: number = 0

	public constructor(input?: api.ResponseData) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public getZnr(): string {
		if (this.list.length === 1) {
			return this.list[0].zNr ?? ''
		}
		if (this.list.length > 1) {
			if (this.list[0].zNr !== this.list[this.list.length - 1].zNr) {
				return this.list[0].zNr + '–' + this.list[this.list.length - 1].zNr
			} else {
				return this.list[0].zNr ?? ''
			}
		}
		return ''
	}

	public getZnrNumber(): string {
		const length: number = this.list.length
		if (length === 1) {
			return this.list[0].zNrNumber ?? ''
		}
		if (length > 1) {
			if ((this.list[0].zNrNumber ?? 0) < (this.list[length - 1].zNrNumber ?? 0)) {
				return this.list[0].zNrNumber + '–' + this.list[length - 1].zNrNumber
			} else {
				return this.list[0].zNrNumber ?? ''
			}
		}
		return ''
	}

	public getSortOrder(): string {
		let output: number = this.list[this.list.length - 1].sortOrder ?? 0
		for (const i of this.list) {
			if (!i.sortOrder) continue
			if (i.sortOrder > output) output = i.sortOrder
		}
		return String(output + 50)
	}

	public getDescription(): string {
		const length: number = this.list.length
		if (length === 1) {
			return 'Satz, ' + length + ' Wert'
		}
		if (length > 1) {
			return 'Satz, ' + length + ' Werte'
		}
		return ''
	}

	public getTitle(): string {
		return this.list[0].titleDe ?? this.list[0].nameDe ?? ''
	}

	public getPriceString(key: keyof Product): string {
		const price: number = this.getPrice(key)
		if (price) return getPriceString(price, 'de-CH')
		return '–'
	}

	public getCategory(): number | null {
		return this.list[0].category
	}

	public getYear(): string {
		const allYears: (string | null)[] = this.list
			.map((e: Product) => {
				return e.year
			})
			.filter((e: string | null) => {
				if (e !== null) {
					return e
				}
			})

		if (allYears.length === 1) {
			return this.list[0].year ?? ''
		}
		if (allYears.length > 1) {
			const firstYear: string = allYears[0] ?? ''
			const lastYear: string = allYears[allYears.length - 1] ?? ''
			if (firstYear === lastYear) {
				return firstYear
			}
			return firstYear + '–' + lastYear
		}
		return ''
	}

	public getPrice(key: keyof Product): number {
		const specialKeys: string[] = ['fdc', 'firstDayBlock', 'fullstampFirstDay']
		let output: number = 0
		for (const i of this.list) {
			if (typeof i[key] !== 'number') continue
			const value: number = Number(i[key])
			if (isNaN(value)) continue
			if (specialKeys.includes(key)) {
				return value
			}
			output = output + value
		}
		output = Math.round(output * 100) / 100
		return output
	}

	private mapApiToThis(input: api.ResponseData) {
		this.count = input.count
		this.list = []
		for (const i of input.data) {
			const product = new Product(i)
			this.list.push(product)
		}
	}

	public async readFromApi(productId: number) {
		const response: api.ResponseData | null = await api.call(productId)
		if (response) {
			this.mapApiToThis(response)
		}
	}
}
