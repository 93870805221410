export default function renderLabel(label: string | JSX.Element, price: string): JSX.Element {
	return (
		<>
			{label}:{' '}
			<span style={{ whiteSpace: 'nowrap' }}>
				CHF <strong>{price}</strong>
			</span>
		</>
	)
}
