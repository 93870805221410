import axios from 'axios'

import UserListApi from 'interfaces/UserListApi'

export async function call(): Promise<UserListApi | null> {
	try {
		const response: Response = await axios.get('/user-list/all')
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: UserListApi
}

export type ResponseData = {
	data: UserListApi[]
}
