import ProductSale from 'classes/ProductSale'
import ProductSaleSimple from 'components/ProductSaleList/ProductSaleSimple'

interface Props {
	items: (ProductSale | null)[]
}

export default function ProductSaleSimpleList(props: Props) {
	return (
		<div className="d-flex flex-column" style={{ gap: '10px' }}>
			{props.items.map((e: ProductSale | null, i: number) => {
				return (
					<div key={i}>
						<ProductSaleSimple productSale={e} />
					</div>
				)
			})}
		</div>
	)
}
