export enum SortColumn {
	ID = 'id',
	AUCTIONID = 'type',
	ZNR = 'znr',
	YEAR = 'year',
	NAME = 'name',
	CATEGORY = 'category',
	SORTORDER = 'sortOrder',
	PRICE = 'price',
	PRICEPROPOSAL = 'priceProposal',
	PRICEMARKET = 'priceMarket',
	PRICEPURCHASE = 'pricePurchase',
}
