import { useNavigate } from 'react-router'

import ProductSale from 'classes/ProductSale'
import ProductSaleList from 'classes/ProductSaleList'
import Pagination from 'components/Pagination'
import clone from 'functions/clone'
import scrollToTop from 'functions/scrollToTop'
import ProductDetailSearch from 'pages/ProductSaleDetail/classes/Search'
import Search from 'pages/ProductSaleSearch/classes/Search'
import ActionButtons from 'pages/ProductSaleSearch/components/ActionButtons'
import AmountInput from 'pages/ProductSaleSearch/components/AmountInput'
import SortOrderSelectComponent from 'pages/ProductSaleSearch/components/SortOrderSelect'

import styles from './styles.module.scss'

interface Props {
	productSaleList: ProductSaleList
	setProductSellList: (input: ProductSaleList) => void
	search: Search
	handleSearch: (resetPageIndex?: boolean) => Promise<void>
	handlePageChange: (input: number) => void
	handleDelete: () => void
	className?: string
}

export default function ProductListComponent(props: Props) {
	const navigate = useNavigate()

	function renderSold(input: boolean | null): JSX.Element {
		if (input) {
			return <span className="badge bg-danger">Verkauft</span>
		} else {
			return <span className="badge bg-success">Verfügbar</span>
		}
	}

	function renderType(productSale: ProductSale): JSX.Element {
		switch (productSale.type) {
			case 1:
				return (
					<span className="badge bg-success" style={{ marginRight: '8px' }}>
						Shop
					</span>
				)
			case 2:
				return (
					<span className="badge bg-info" style={{ marginRight: '8px' }}>
						Auktion
					</span>
				)
			default:
				return (
					<span className="badge bg-secondary" style={{ marginRight: '8px' }}>
						Unbekannt
					</span>
				)
		}
	}

	function renderPicture(input: ProductSale) {
		if (input.imageList.all[0]) {
			return (
				<img
					className={styles.picture}
					src={[input.imageList.all[0].thumbnail].join('/')}
				/>
			)
		}
		return <div className={styles.picturePlaceholder}></div>
	}

	function handleSelectionClick(event: React.MouseEvent, id: number) {
		event.stopPropagation()
		props.productSaleList.toggleSelectById(id)
		props.setProductSellList(clone(props.productSaleList))
	}

	function handleGoToClick(id: number) {
		localStorage.setItem('productSearch', window.location.pathname + window.location.search)
		scrollToTop()
		const search: ProductDetailSearch = new ProductDetailSearch()
		search.id.value = id
		search.auctionId.value = props.search.auction.value
		search.userId.value = props.search.user.value
		search.availability = props.search.availability
		navigate('/product/detail' + search.mapToUrl())
	}

	function renderItem(input: ProductSale, key: number): JSX.Element {
		return (
			<div
				className={[
					'd-flex',
					'justify-content-between',
					'align-items-center mb-1',
					styles.item,
					input.selected ? styles.selected : '',
				].join(' ')}
				key={key}
				onClick={() => handleGoToClick(input.id)}
			>
				{renderPicture(input)}

				<div className="flex-grow-1">
					<div>
						{renderType(input)}
						<strong>{input.nameDe}</strong>
					</div>
					<div>
						<label className={styles.labelstyle}>Kategorie</label>
						{input.category?.fullName ?? '–'}
					</div>
					<div>
						<label className={styles.labelstyle}>Auktion</label>
						{input.auction?.name ?? '–'}
					</div>
				</div>

				<div style={{ width: '165px' }}>
					<div className="d-flex justify-content-between align-items-end">
						<label className={styles.labelstyle}>Katalogwert</label>
						{input.priceProposal ?? '–'}
					</div>
					<div className="d-flex justify-content-between align-items-end">
						<label className={styles.labelstyle}>Verkaufspreis</label>
						<div>{input.price ?? '–'}</div>
					</div>
				</div>

				<div style={{ width: '165px', marginRight: '20px' }}>
					<div className="d-flex justify-content-between align-items-end">
						<label className={styles.labelstyle}>Einkaufspreis</label>
						{input.pricePurchase ?? '–'}
					</div>
					<div className="d-flex justify-content-between align-items-end">
						<label className={styles.labelstyle}>Marktpreis</label>
						{input.priceMarket ?? '–'}
					</div>
				</div>

				<div style={{ width: '200px' }}>
					<div>
						<label className={styles.labelstyle} style={{ marginRight: '27px' }}>
							Id
						</label>
						{input.id}
					</div>
					<div>
						<label className={styles.labelstyle} style={{ marginRight: '7px' }}>
							Z-Nr.
						</label>
						{input.zNr ?? '–'}
					</div>

					<div>
						<label className={styles.labelstyle} style={{ marginRight: '12px' }}>
							Jahr
						</label>
						{input.year ?? '–'}
					</div>
				</div>

				<div style={{ width: '100px' }}>
					<div className={styles.labelstyle} style={{ marginBottom: '5px' }}>
						{input.sortOrder ? '#' : ''}
						<span style={{ color: '#004e91' }}>{input.sortOrder ?? '–'}</span>
					</div>
					<div className={styles.labelstyle} style={{ marginBottom: '5px' }}>
						{input.getProductNumberString()}
					</div>
					{renderSold(input.sold)}
				</div>

				<div style={{ width: '30px' }}>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{
							border: '1px solid #aaa',
							width: '20px',
							height: '20px',
							backgroundColor: input.selected ? '#ac9259' : 'white',
						}}
						onClick={(event: React.MouseEvent) => handleSelectionClick(event, input.id)}
					></div>
				</div>
			</div>
		)
	}
	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<div className="d-flex" style={{ gap: '5px' }}>
					<AmountInput search={props.search} handleSearch={props.handleSearch} />
					<SortOrderSelectComponent
						search={props.search}
						handleSearch={props.handleSearch}
					/>
				</div>
				<ActionButtons
					productSaleList={props.productSaleList}
					handleDelete={props.handleDelete}
					setProductSellList={props.setProductSellList}
				/>
			</div>
			<hr />
			<Pagination
				countOfAllListItems={props.productSaleList.count}
				pageIndex={props.search.page.get()}
				setPageIndex={props.handlePageChange}
				listItemAmount={props.search.pageCount.get()}
				maxNumberOfButtons={8}
			/>
			<div className="mb-3"></div>
			<div className={props.className}>
				{props.productSaleList.list.map((e: ProductSale, i: number) => {
					return renderItem(e, i)
				})}
			</div>
		</>
	)
}
