import { faRectangleList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router'

import Collection from 'classes/Collection'
import { APIURL } from 'constants/urls'

interface Props {
	collection: Collection
}

export default function ListItem({ collection }: Props): JSX.Element {
	const navigate = useNavigate()

	function handleGoToClick(id: number) {
		navigate('/collection/detail?id=' + id)
	}

	function renderImage() {
		return (
			<div
				style={{ height: '60px', width: '60px', backgroundColor: '#aaa' }}
				className="d-flex justify-content-center align-items-center"
			>
				<FontAwesomeIcon icon={faRectangleList} size="lg" />
			</div>
		)
	}

	function renderButtonDownload(collection: Collection): JSX.Element {
		const downloadLink: string =
			APIURL + '/product-sale-list/export-collection/' + collection.id
		return (
			<a
				type="button"
				href={downloadLink}
				className="btn btn-outline-success btn-sm"
				onClick={(event) => {
					event.stopPropagation()
				}}
			>
				Excel-Download
			</a>
		)
	}

	function renderWholeName(input: Collection): JSX.Element {
		return (
			<div>
				<span style={{ color: '#aaa', marginRight: '10px' }}>ID {input.id}</span>
				<span>{input.name}</span>
			</div>
		)
	}

	return (
		<div
			className="d-flex justify-content-between align-items-center mb-1"
			style={{
				backgroundColor: 'white',
				paddingRight: '20px',
				gap: '20px',
				cursor: 'pointer',
			}}
			onClick={() => handleGoToClick(collection.id ?? 0)}
		>
			{renderImage()}

			<div className="flex-grow-1">{renderWholeName(collection)}</div>

			<div style={{ width: '250px' }}>
				<div>
					<label style={labelStyle}>Ersteller</label>
					<br />
					{collection.creator.getName()}
				</div>
			</div>

			<div style={{ width: '250px' }}>
				<div>
					<label style={labelStyle}>Anzahl Produkte</label>
					{collection.products.length}
				</div>
				<div>
					<label style={labelStyle}>Anzahl Benutzer</label>
					{collection.users.length}
				</div>
			</div>
			<div>{renderButtonDownload(collection)}</div>
		</div>
	)
}

const labelStyle: React.CSSProperties = {
	color: '#999',
	fontSize: '0.8em',
	paddingRight: '6px',
}
