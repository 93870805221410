import { QueryParams } from 'functions/url'
import AdvancedSearchKey from 'interfaces/Search/AdvancedSearchKey'

export default class NullableString implements AdvancedSearchKey {
	public id: string
	public value: string | null
	public defaultValue?: string

	constructor(id: string, defaultValue?: string) {
		this.id = id
		this.value = null
		this.defaultValue = defaultValue
	}

	public get(): string {
		if (this.value === null) {
			if (this.defaultValue) {
				return this.defaultValue
			} else {
				return ''
			}
		}
		return this.value
	}

	public reset() {
		this.value = null
	}

	public mapFromResponse(input: string) {
		this.value = input
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				this.value = value ? String(value) : null
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.value) {
			return this.id + '=' + this.value
		}
		return null
	}

	public mapToApi(): string | null {
		if (this.value === null && this.defaultValue) {
			return this.defaultValue
		}
		return this.value
	}
}
