import { useNavigate } from 'react-router'

import Auction from 'classes/Auction'
import AuctionList from 'classes/AuctionList'
import ActionButtons from 'components/Buttons/ActionButtons'
import { APIURL } from 'constants/urls'
import clone from 'functions/clone'

interface Props {
	auctionList: AuctionList
	setAuctionList: (input: AuctionList) => void
	className?: string
}

export default function AuctionListComponent(props: Props) {
	const navigate = useNavigate()

	function handleSelectionClick(event: React.MouseEvent, id: number) {
		event.stopPropagation()
		props.auctionList.toggleSelectById(id)
		props.setAuctionList(clone(props.auctionList))
	}

	function handleGoToClick(id: number) {
		navigate('/auction/detail?id=' + id)
	}

	function getBackgroundColor(selected: boolean): string {
		if (selected) {
			return '#eee9dd'
		}
		return 'white'
	}

	function renderExpired(input: Date | null): JSX.Element {
		if (input === null) {
			return <span className="badge bg-danger">Unbekannt</span>
		}
		if (input < new Date()) {
			return <span className="badge bg-secondary">Vorbei</span>
		} else {
			return <span className="badge bg-dark">Anstehend</span>
		}
	}

	function renderButtonDownload(auction: Auction): JSX.Element {
		const downloadLink: string = APIURL + '/product-sale-list/export-auction/' + auction.id
		return (
			<a
				type="button"
				href={downloadLink}
				className="btn btn-outline-success btn-sm"
				onClick={(event) => {
					event.stopPropagation()
				}}
			>
				Excel-Download
			</a>
		)
	}

	function renderImage() {
		return (
			<div
				style={{ height: '60px', width: '60px', backgroundColor: 'grey' }}
				className="d-flex justify-content-center align-items-center"
			>
				<object
					data={'../img/gavel-solid.svg'}
					type="image/svg+xml"
					style={{ width: '30px', height: '30px' }}
				></object>
			</div>
		)
	}

	function renderItem(input: Auction, key: number): JSX.Element {
		return (
			<div
				className="d-flex justify-content-between align-items-center mb-1"
				style={{
					backgroundColor: getBackgroundColor(input.selected),
					paddingRight: '20px',
					gap: '20px',
					cursor: 'pointer',
				}}
				key={key}
				onClick={() => handleGoToClick(input.id)}
			>
				{renderImage()}

				<div className="flex-grow-1">
					<div>{input.name}</div>
				</div>

				<div style={{ width: '200px' }}>
					<div>
						<label style={labelStyle}>Start</label>
						{input.getDate('start')}
					</div>
					<div>
						<label style={labelStyle}>Ende</label>
						{input.getDate('end')}
					</div>
				</div>

				<div style={{ width: '200px' }}>
					<div>
						<label style={labelStyle}>Id</label>
						{input.id}
					</div>
					<div>
						<label style={labelStyle}>Erstellt</label>
						{input.getDate('createdAt')}
					</div>
				</div>

				<div style={{ width: '100px' }}>{renderExpired(input.end)}</div>

				<div style={{ width: '150px' }}>{renderButtonDownload(input)}</div>

				<div style={{ width: '30px' }}>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{
							border: '1px solid #aaa',
							width: '20px',
							height: '20px',
							backgroundColor: input.selected ? '#ac9259' : 'white',
						}}
						onClick={(event: React.MouseEvent) => handleSelectionClick(event, input.id)}
					></div>
				</div>
			</div>
		)
	}
	return (
		<>
			<ActionButtons
				list={props.auctionList}
				setList={props.setAuctionList}
				buttons={['delete', 'selectAll', 'editAll']}
			/>
			<div className={props.className}>
				{props.auctionList.searched.map((e: Auction, i: number) => {
					return renderItem(e, i)
				})}
			</div>
		</>
	)
}

const labelStyle: React.CSSProperties = {
	color: '#999',
	fontSize: '0.8em',
	paddingRight: '6px',
}
