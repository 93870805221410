import ImageApi from '../interfaces/ImageApi'
import Image from './Image'

export default class ImageList {
	public all: Image[] = []

	public constructor(input?: ImageApi[]) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public mapApiToThis(input: ImageApi[]): void {
		this.all = []
		for (const i of input) {
			const image = new Image()
			image.mapApiToThis(i)
			this.all.push(image)
		}
	}
}
