interface Props {
	label?: string
	value?: string
	changeValue?: (input: string) => void
	placeholder?: string
	color?: string
	disabled?: boolean
	onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

export default function InputString(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.changeValue) {
			const value: string = e.currentTarget.value
			props.changeValue(value)
		}
	}

	function renderLabel(): JSX.Element {
		if (props.label) {
			return (
				<label htmlFor="basic-url" className="form-label label-sm">
					{props.label}
				</label>
			)
		}
		return <></>
	}

	return (
		<div>
			{renderLabel()}
			<div className="input-group">
				<input
					type="text"
					className="form-control"
					id="basic-url"
					aria-describedby="basic-addon3"
					value={props.value}
					placeholder={props.placeholder}
					onChange={handleChange}
					style={{ backgroundColor: props.color }}
					disabled={props.disabled}
					onKeyDown={props.onKeyDown}
				/>
			</div>
		</div>
	)
}
