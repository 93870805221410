import { useEffect } from 'react'
import { useState } from 'react'

import AuctionList from 'classes/AuctionList'
import CategoryList from 'classes/CategoryList'
import CollectionList from 'classes/CollectionList'
import ProductSale from 'classes/ProductSale'
import UserList from 'classes/UserList'
import clone from 'functions/clone'
import UrlHook from 'hooks/Url'
import ProductSaleComponent from 'pages/ProductSaleDetail/components/IdSingle/ProductSaleComponent'
import SourceProductComponent from 'pages/ProductSaleDetail/components/SourceProduct'

import Search from '../../classes/Search'

interface Props {
	id: string
	setIsLoading: (input: boolean) => void
	categoryList: CategoryList
	auctionList: AuctionList
	collectionList: CollectionList
	userList: UserList
}

export default function IdSingle(props: Props) {
	const [productSale, setProductSale] = useState<ProductSale>(new ProductSale())
	const [search, setSearch] = useState<Search>(new Search())
	const [getUrl] = UrlHook<Search>(search)

	useEffect(() => {
		initialize()
	}, [props.id])

	async function initialize() {
		props.setIsLoading(true)
		initializeSearch()
		try {
			await productSale.readFromApi(props.id, search.mapToApi())
			setProductSale(clone(productSale))
		} finally {
			props.setIsLoading(false)
		}
	}

	function initializeSearch() {
		getUrl()
		setSearch(clone(search))
	}

	return (
		<div className="row">
			<div
				className="col-6"
				style={{
					backgroundColor: 'white',
					paddingBottom: '12px',
					paddingTop: '15px',
				}}
			>
				<ProductSaleComponent
					search={search}
					productSale={productSale}
					setProductSale={setProductSale}
					categoryList={props.categoryList}
					userList={props.userList}
					auctionList={props.auctionList}
					collectionList={props.collectionList}
					setIsLoading={props.setIsLoading}
				/>
			</div>
			<div
				className="col-6"
				style={{
					backgroundColor: '#808080',
					paddingBottom: '12px',
					paddingTop: '15px',
				}}
			>
				<SourceProductComponent
					productSale={productSale}
					setProductSell={setProductSale}
					categoryList={props.categoryList}
				/>
			</div>
		</div>
	)
}
