import axios from 'axios'

import { APIURL } from 'constants/urls'

export async function apiCall(id: number): Promise<ResponseData | null> {
	const url = APIURL + `/link-sale/${id}`
	try {
		const response: Response = await axios.delete(url)
		return response.data.data
	} catch (e: unknown) {
		return null
	}
}

export interface Response {
	data: {
		data: ResponseData
		status: number
	}
}

export interface ResponseData {
	id: number
	position: number
}
