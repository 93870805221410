import Image from 'classes/Image'
import { getPriceString } from 'functions/numberString'
import ProductApi from 'interfaces/ProductApi'

export type ProductStringAttribute = 'nameDe' | 'year' | 'additionalInfo10' | 'zNr' | 'zNrNumber'
export type ProductNumberAttribute = 'sortOrder'

export default class Product {
	public id: number = 0
	public sortOrder: number | null = null
	public hauserAddition: string | null = null
	public zNrBefore: string | null = null
	public zNrNumber: string | null = null
	public zNrAfter: string | null = null
	public zNrColorVariant: string | null = null
	public zNrAbart: string | null = null
	public zNrAbartDescription: string | null = null
	public setNumber: string | null = null
	public zNr: string | null = null
	public miNr: string | null = null
	public year: string | null = null
	public nameDe: string | null = null
	public nameFr: string | null = null
	public titleDe: string | null = null
	public colorDe: string | null = null
	public colorFr: string | null = null
	public descriptionLongDe: string | null = null
	public descriptionLongFr: string | null = null
	public descriptionShortDe: string | null = null
	public descriptionShortFr: string | null = null
	public descriptionDe: string | null = null
	public descriptionFr: string | null = null
	public valueCurrency: string | null = null
	public issueDate: string | null = null
	public firstDayBlock: number | null = null
	public firstDayBlockDe: number | null = null
	public firstDayBlockFr: number | null = null
	public firstDayBlockIt: number | null = null
	public blockMintNeverHinged: number | null = null
	public blockUnstamped: number | null = null
	public block: number | null = null
	public mintNeverHinged: number | null = null
	public unstamped: number | null = null
	public stamped: number | null = null
	public letter: number | null = null
	public fullstamp: number | null = null
	public fullstampFirstDay: number | null = null
	public fullstampDe: number | null = null
	public fullstampFr: number | null = null
	public fullstampIt: number | null = null
	public fdc: number | null = null
	public fdcDe: number | null = null
	public fdcFr: number | null = null
	public fdcIt: number | null = null
	public category: number | null = null
	public priceLayout: number | null = null
	public pairUnstamped: number | null = null
	public valueLevel: number | null = null
	public pairLetter: number | null = null
	public periodOfValidity: string | null = null
	public printRun: string | null = null
	public additionalInfo01: string | null = null
	public additionalInfo02: string | null = null
	public additionalInfo03: string | null = null
	public additionalInfo04: string | null = null
	public additionalInfo05: string | null = null
	public additionalInfo06: string | null = null
	public additionalInfo07: string | null = null
	public additionalInfo08: string | null = null
	public additionalInfo09: string | null = null
	public additionalInfo10: string | null = null
	public pair_stamped: number | null = null
	public image: Image = new Image()

	public constructor(input?: ProductApi) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public getPriceString(key: keyof Product): string {
		const price: number = this.getPrice(key)
		if (price) return getPriceString(price, 'de-CH')
		return '–'
	}

	public getPrice(key: keyof Product): number {
		const value: number = Number(this[key])
		if (isNaN(value)) return 0
		return Math.round(value * 100) / 100
	}

	private mapApiToThis(input: ProductApi) {
		this.id = input.id
		this.sortOrder = input.sortOrder ? input.sortOrder : null
		this.hauserAddition = input.hauserAddition
		this.zNrBefore = input.zNrBefore
		this.zNrNumber = input.zNrNumber
		this.zNrAfter = input.zNrAfter
		this.zNrColorVariant = input.zNrColorVariant
		this.zNrAbart = input.zNrAbart
		this.zNrAbartDescription = input.zNrAbartDescription
		this.setNumber = input.setNumber
		this.zNr = input.zNr
		this.miNr = input.miNr
		this.year = input.year
		this.nameDe = input.nameDe
		this.nameFr = input.nameFr
		this.titleDe = input.titleDe
		this.colorDe = input.colorDe
		this.colorFr = input.colorFr
		this.descriptionLongDe = input.descriptionLongDe
		this.descriptionLongFr = input.descriptionLongFr
		this.descriptionShortDe = input.descriptionShortDe
		this.descriptionShortFr = input.descriptionShortFr
		this.descriptionDe = input.descriptionDe
		this.descriptionFr = input.descriptionFr
		this.valueCurrency = input.valueCurrency
		this.issueDate = input.issueDate
		this.firstDayBlock = input.firstDayBlock
		this.firstDayBlockDe = input.firstDayBlockDe
		this.firstDayBlockFr = input.firstDayBlockFr
		this.firstDayBlockIt = input.firstDayBlockIt
		this.blockMintNeverHinged = input.blockMintNeverHinged
		this.blockUnstamped = input.blockUnstamped
		this.block = input.block
		this.mintNeverHinged = input.mintNeverHinged
		this.unstamped = input.unstamped
		this.stamped = input.stamped
		this.letter = input.letter
		this.fullstamp = input.fullstamp
		this.fullstampFirstDay = input.fullstampFirstDay
		this.fullstampDe = input.fullstampDe
		this.fullstampFr = input.fullstampFr
		this.fullstampIt = input.fullstampIt
		this.fdc = input.fdc
		this.fdcDe = input.fdcDe
		this.fdcFr = input.fdcFr
		this.fdcIt = input.fdcIt
		this.category = input.category
		this.priceLayout = input.priceLayout
		this.pairUnstamped = input.pairUnstamped
		this.valueLevel = input.valueLevel
		this.pairLetter = input.pairLetter
		this.periodOfValidity = input.periodOfValidity
		this.printRun = input.printRun
		this.additionalInfo01 = input.additionalInfo01
		this.additionalInfo02 = input.additionalInfo02
		this.additionalInfo03 = input.additionalInfo03
		this.additionalInfo04 = input.additionalInfo04
		this.additionalInfo05 = input.additionalInfo05
		this.additionalInfo06 = input.additionalInfo06
		this.additionalInfo07 = input.additionalInfo07
		this.additionalInfo08 = input.additionalInfo08
		this.additionalInfo09 = input.additionalInfo09
		this.additionalInfo10 = input.additionalInfo10
		this.pair_stamped = input.pair_stamped
		this.image.mapApiToThis(input.image, true)
	}
}
