import Auction from 'classes/Auction'
import ProductSale from 'classes/ProductSale'
import ProductSaleList from 'classes/ProductSaleList'
import clone from 'functions/clone'
import ActionButtons from 'pages/AuctionDetail/components/ActionButtons'
import InputDate from 'pages/ProductSaleDetail/components/InputDate'
import InputString from 'pages/ProductSaleDetail/components/InputString'
import InputToggle from 'pages/ProductSaleDetail/components/InputToggle'
import SelectComponent from 'pages/ProductSaleDetail/components/Select'
import TextArea from 'pages/ProductSaleDetail/components/TextArea'
import getProductSaleOptionList from 'pages/ProductSaleDetail/functions/getProductSaleOptionList'

interface Props {
	auction: Auction
	setAuction: (input: Auction) => void
	setIsLoading: (input: boolean) => void
	productSaleList: ProductSaleList
}

export default function AuctionRendering(props: Props) {
	function changeActive(input: boolean) {
		props.auction.active = input
		props.setAuction(clone(props.auction))
	}

	function createChangeHighlightFunction(index: '1' | '2' | '3'): (input: string) => void {
		return (input: string) => {
			const productSale: ProductSale | undefined = props.productSaleList.getById(
				Number(input)
			)
			if (productSale) {
				props.auction.highlights[index] = productSale
				props.setAuction(clone(props.auction))
			}
		}
	}

	function changeName(input: string) {
		props.auction.name = input
		props.setAuction(clone(props.auction))
	}

	function changeDateStart(input: Date) {
		props.auction.start = input
	}

	function changeDateEnd(input: Date) {
		props.auction.end = input
	}

	function changeDescription(input: string) {
		props.auction.description = input
	}

	function getHighlightDefaultValue(index: '1' | '2' | '3'): string {
		const highlight: null | ProductSale = props.auction.highlights[index]
		if (highlight) {
			return String(highlight.id ?? 0)
		}
		return '0'
	}

	return (
		<>
			<ActionButtons
				auction={props.auction}
				setAuction={props.setAuction}
				setIsLoading={props.setIsLoading}
			/>
			<div className="row mt-4">
				<div className="col-8">
					<InputString
						label="Name"
						value={props.auction.name ?? ''}
						changeValue={changeName}
					/>
				</div>
				<div className="col-4 d-flex justify-content-end">
					<InputToggle
						style={{ marginTop: '28px' }}
						label="Aktiv"
						defaultValue={props.auction.active ?? undefined}
						changeValue={changeActive}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-6">
					<InputDate
						label="Start"
						defaultValue={props.auction.start ?? undefined}
						changeValue={changeDateStart}
					/>
				</div>
				<div className="col-6">
					<InputDate
						label="Ende"
						defaultValue={props.auction.end ?? undefined}
						changeValue={changeDateEnd}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-12">
					<TextArea
						label="Beschrieb"
						defaultValue={props.auction.description ?? ''}
						changeValue={changeDescription}
						rows={8}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-12">
					<SelectComponent
						optionList={getProductSaleOptionList(props.productSaleList)}
						label="Produkt-Highlight 1"
						changeValue={createChangeHighlightFunction('1')}
						defaultValue={getHighlightDefaultValue('1')}
					/>
					<SelectComponent
						optionList={getProductSaleOptionList(props.productSaleList)}
						label="Produkt-Highlight 2"
						changeValue={createChangeHighlightFunction('2')}
						defaultValue={getHighlightDefaultValue('2')}
					/>
					<SelectComponent
						optionList={getProductSaleOptionList(props.productSaleList)}
						label="Produkt-Highlight 3"
						changeValue={createChangeHighlightFunction('3')}
						defaultValue={getHighlightDefaultValue('3')}
					/>
				</div>
			</div>
		</>
	)
}
