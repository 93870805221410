import axios, { AxiosError } from 'axios'

import UserApi from 'interfaces/UserApi'

export async function call(input: Request): Promise<Response> {
	try {
		const response: Response = await axios.post('/user', input)
		return {
			status: response.status,
			data: response.data,
		}
	} catch (e: unknown) {
		const status: number = (e as AxiosError).response?.status ?? 400
		return {
			status: status,
			data: undefined,
		}
	}
}

export type Request = UserApi

export type Response = {
	status: number
	data?: ResponseData
}

export type ResponseData = {
	data: number
}
