import CategoryList from 'classes/CategoryList'
import Product from 'classes/Product'
import ProductList from 'classes/ProductList'
import ImageWithFallback from 'components/ImageWithFallback'
import LoadingSimple from 'components/Loading/Simple/LoadingSimple'
import { defaultImagePath } from 'constants/defaultImagePath'
import Styles from 'types/Style'

interface Props {
	categoryList: CategoryList
	productList: ProductList
	setProduct: (input: Product | undefined) => void
	isLoading: boolean
}

export default function SourceProductSearchList(props: Props) {
	function handleClick(input: Product) {
		props.setProduct(input)
	}

	function renderImage(input: Product): JSX.Element {
		return (
			<ImageWithFallback
				fallback={defaultImagePath}
				src={input.image.thumbnail ?? ''}
				style={styles.picture}
			/>
		)
	}

	if (props.isLoading) return <LoadingSimple />
	return (
		<div className="d-flex flex-column" style={styles.all}>
			{props.productList.list.map((e: Product, i: number) => {
				return (
					<div
						onClick={() => handleClick(e)}
						key={i}
						style={styles.listItem}
						className="d-flex"
					>
						<div>{renderImage(e)}</div>
						<div>
							<div className="badge bg-secondary">
								{props.categoryList.getFullNameById(e.category ?? 0)}
							</div>
							<div style={styles.name}>
								<b>{e.nameDe}</b>
							</div>
							<div style={styles.description}>{e.descriptionDe}</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}

const styles: Styles = {
	all: { gap: '5px', color: 'white' },
	listItem: {
		backgroundColor: '#444444',
		padding: '10px',
		cursor: 'pointer',
		gap: '10px',
	},
	picture: {
		objectFit: 'contain',
		backgroundColor: 'black',
		width: '100px',
		height: '100px',
	},
	name: {
		marginTop: '5px',
	},
	description: {
		marginTop: '5px',
	},
}
