import ProductSale from 'classes/ProductSale'
import { CheckboxKeys } from 'classes/ProductSale'
import clone from 'functions/clone'
import CheckboxElement from 'types/CheckboxElement'

interface Props {
	productSale: ProductSale
	setProductSell: (input: ProductSale) => void
}

export default function CheckboxesProductSell(props: Props) {
	function handleChange(key: CheckboxKeys): void {
		props.productSale.toggleCheck(key)
		props.setProductSell(clone(props.productSale))
	}

	function renderCheckboxes(input: CheckboxElement[]): JSX.Element {
		return (
			<>
				{input.map((e: CheckboxElement, i: number) => {
					return (
						<div className="form-check" key={i}>
							<input
								className="form-check-input"
								type="checkbox"
								checked={e.checked}
								id={e.label}
								onChange={() => handleChange(e.key as CheckboxKeys)}
							/>
							<label className="form-check-label" htmlFor={e.label}>
								{e.label}
							</label>
						</div>
					)
				})}
			</>
		)
	}

	return (
		<div className="row">
			<div className="col-6">
				{renderCheckboxes(props.productSale.mapToCheckboxElement().slice(0, 6))}
			</div>
			<div className="col-6">
				{renderCheckboxes(props.productSale.mapToCheckboxElement().slice(6))}
			</div>
		</div>
	)
}
