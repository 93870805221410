import { useNavigate } from 'react-router'

import ProductSale from 'classes/ProductSale'

import styles from './styles.module.css'

interface Props {
	productSale: ProductSale | null
}

export default function ProductSaleSimple(props: Props) {
	const navigate = useNavigate()

	function handleGoToClick(id: number): void {
		navigate('/product/detail?id=' + id)
	}

	function getText(input: string | null): string {
		if (input) return input
		return '–'
	}

	if (props.productSale) {
		return (
			<div
				className={['d-flex', 'align-items-stretch', styles.item].join(' ')}
				onClick={() => handleGoToClick(props.productSale?.id ?? 0)}
			>
				<img
					src={props.productSale.imageList.all[0]?.thumbnail ?? ''}
					className={styles.img}
				/>
				<div className={styles.text}>
					<div>
						Id: <strong>{props.productSale.id}</strong>
					</div>
					<div>{getText(props.productSale.nameDe)}</div>
					<p className={styles.grey}>{getText(props.productSale.descriptionDe)}</p>
				</div>
			</div>
		)
	} else {
		return (
			<div className={['d-flex', 'align-items-center', styles.item].join(' ')}>
				<div className={styles['void-img']}></div>
				<div className={styles.text}>
					<p className={styles.grey}>Kein Highlight</p>
				</div>
			</div>
		)
	}
}
