import { SortColumn } from 'enums/SortColumn'
import { SortOrder } from 'enums/SortOrder'
import Search from 'pages/ProductSaleSearch/classes/Search'

interface Props {
	search: Search
	handleSearch: (resetPageIndex?: boolean) => Promise<void>
}

type SortObject = {
	id: number
	key: SortColumn
	order: SortOrder
	name: string
}

const sortOrderTypes: SortObject[] = [
	{ id: 1, key: SortColumn.ID, order: SortOrder.ASC, name: 'Id' },
	{ id: 2, key: SortColumn.ID, order: SortOrder.DESC, name: 'Id' },
	{ id: 13, key: SortColumn.SORTORDER, order: SortOrder.ASC, name: 'Sortierung' },
	{ id: 14, key: SortColumn.SORTORDER, order: SortOrder.DESC, name: 'Sortierung' },
	{ id: 3, key: SortColumn.AUCTIONID, order: SortOrder.ASC, name: 'Auktionen' },
	{ id: 4, key: SortColumn.AUCTIONID, order: SortOrder.DESC, name: 'Auktionen' },
	{ id: 5, key: SortColumn.ZNR, order: SortOrder.ASC, name: 'ZNr.' },
	{ id: 6, key: SortColumn.ZNR, order: SortOrder.DESC, name: 'ZNr.' },
	{ id: 7, key: SortColumn.YEAR, order: SortOrder.ASC, name: 'Jahr' },
	{ id: 8, key: SortColumn.YEAR, order: SortOrder.DESC, name: 'Jahr' },
	{ id: 9, key: SortColumn.NAME, order: SortOrder.ASC, name: 'Name' },
	{ id: 10, key: SortColumn.NAME, order: SortOrder.DESC, name: 'Name' },
	{ id: 11, key: SortColumn.CATEGORY, order: SortOrder.ASC, name: 'Kategorie' },
	{ id: 12, key: SortColumn.CATEGORY, order: SortOrder.DESC, name: 'Kategorie' },
	{ id: 15, key: SortColumn.PRICE, order: SortOrder.ASC, name: 'Katalogwert' },
	{ id: 16, key: SortColumn.PRICE, order: SortOrder.DESC, name: 'Katalogwert' },
	{ id: 17, key: SortColumn.PRICEPROPOSAL, order: SortOrder.ASC, name: 'Verkaufkaufspreis' },
	{ id: 18, key: SortColumn.PRICEPROPOSAL, order: SortOrder.DESC, name: 'Verkaufkaufspreis' },
	{ id: 19, key: SortColumn.PRICEMARKET, order: SortOrder.ASC, name: 'Marktpreis' },
	{ id: 110, key: SortColumn.PRICEMARKET, order: SortOrder.DESC, name: 'Marktpreis' },
	{ id: 111, key: SortColumn.PRICEPURCHASE, order: SortOrder.ASC, name: 'Einkaufspreis' },
	{ id: 112, key: SortColumn.PRICEPURCHASE, order: SortOrder.DESC, name: 'Einkaufspreis' },
]

export default function SortOrderSelectComponent(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		const id: number = Number(e.target.value)
		const sortObject: SortObject | undefined = sortOrderTypes.find((e: SortObject) => {
			return e.id === id
		})

		if (sortObject) {
			props.search.sortColumn.value = sortObject.key
			props.search.sortOrder.value = sortObject.order
			props.handleSearch(true)
		} else {
			props.search.sortColumn.value = null
			props.search.sortOrder.value = null
			props.handleSearch(true)
		}
	}

	function getName(input: SortObject): string {
		if (input.order === 'ASC') {
			return '↓ ' + input.name
		}
		return '↑ ' + input.name
	}

	function getSelectValue() {
		const output: SortObject | undefined = sortOrderTypes.find(
			(e) =>
				e.key === props.search.sortColumn.value && e.order === props.search.sortOrder.value
		)
		return output?.id ?? 1
	}

	return (
		<select
			className="form-select form-select-sm"
			onChange={handleChange}
			style={{ width: '250px' }}
			value={getSelectValue()}
		>
			{sortOrderTypes.map((e: SortObject, i: number) => {
				return (
					<option value={e.id} key={i}>
						{getName(e)}
					</option>
				)
			})}
		</select>
	)
}
