import axios from 'axios'

import LinkSaleApi from 'interfaces/LinkSaleApi'

export async function call(
	formData: FormData,
	id: number,
	size: string
): Promise<LinkSaleApi | null> {
	try {
		const response: Response = await axios.post(
			`/link-sale/picture-upload/${id}/${size}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
		return response.data.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: LinkSaleApi
}
