import axios from 'axios'
import { AxiosError } from 'axios'

import CollectionApi from 'interfaces/CollectionApi'

export async function call(input: Request): Promise<Response> {
	try {
		const response: Response = await axios.put('/collection/' + input.id, input)
		return {
			status: response.status,
			data: undefined,
		}
	} catch (e: unknown) {
		const status: number = (e as AxiosError).response?.status ?? 400
		return {
			status: status,
			data: undefined,
		}
	}
}

export type Request = CollectionApi

export type Response = {
	status: number
	data: ResponseData
}

export type ResponseData = undefined
