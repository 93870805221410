import { Option } from 'pages/ProductSaleDetail/components/Select'

export default function getTypeOptionList(): Option[] {
	return [
		{
			value: '0',
			label: 'Undefiniert',
		},
		{
			value: '1',
			label: 'Shop',
		},
		{
			value: '2',
			label: 'Auktion',
		},
	]
}
