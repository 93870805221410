import { useEffect } from 'react'
import { useState } from 'react'

import AuctionList from 'classes/AuctionList'
import CollectionList from 'classes/CollectionList'
import ProductSaleList from 'classes/ProductSaleList'
import UserList from 'classes/UserList'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import clone from 'functions/clone'
import UrlHook from 'hooks/Url'
import ProductListComponent from 'pages/ProductSaleSearch/components/ProductList/ProductList'
import SearchComponent from 'pages/ProductSaleSearch/components/Search'

import Search from './classes/Search'

export default function ProductSaleSearchPage() {
	const [productSaleList, setProductSellList] = useState<ProductSaleList>(new ProductSaleList())
	const [userList, setUserList] = useState<UserList>(new UserList())
	const [auctionList, setAuctionList] = useState<AuctionList>(new AuctionList())
	const [collectionList, setCollectionList] = useState<CollectionList>(new CollectionList())

	const [search, setSearch] = useState<Search>(new Search())
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [getUrl, setUrl] = UrlHook<Search>(search)

	useEffect(() => {
		initialize()
	}, [])

	async function initialize() {
		getUrl()
		await search.initialize()
		await productSaleList.initialize()
		await initializeUserList()
		await initializeCollectionList()
		await initializeAuctionList()
		await handleSearch(false)
	}

	async function initializeCollectionList() {
		try {
			await collectionList.initialize()
			setCollectionList(clone(collectionList))
		} catch (e: unknown) {
			console.error(e)
		}
	}

	async function initializeUserList() {
		try {
			await userList.initialize()
			setUserList(clone(userList))
		} catch (e: unknown) {
			console.error(e)
		}
	}

	async function initializeAuctionList() {
		try {
			await auctionList.initialize()
			setAuctionList(clone(auctionList))
		} catch (e: unknown) {
			console.error(e)
		}
	}

	async function handlePageChange(input: number): Promise<void> {
		search.page.value = input
		setSearch(clone(search))
		await handleSearch(false)
	}

	async function handleSearch(resetPageIndex?: boolean): Promise<void> {
		setIsLoading(true)
		if (resetPageIndex !== false) {
			search.page.value = 0
			setSearch(search)
		}
		setUrl()
		await productSaleList.readFromApi(search.mapToApi())
		setProductSellList(clone(productSaleList))
		setIsLoading(false)
	}

	async function handleDelete(): Promise<void> {
		setIsLoading(true)
		await productSaleList.deleteSelectedFromApi()
		await productSaleList.readFromApi(search.mapToApi())
		setProductSellList(clone(productSaleList))
		setIsLoading(false)
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				<h3 className="mb-4">Alle Produkte</h3>
				<SearchComponent
					search={search}
					setSearch={setSearch}
					handleSearch={handleSearch}
					auctionList={auctionList}
					userList={userList}
					collectionList={collectionList}
				/>
				<hr />
				<ProductListComponent
					productSaleList={productSaleList}
					setProductSellList={setProductSellList}
					handleSearch={handleSearch}
					handlePageChange={handlePageChange}
					handleDelete={handleDelete}
					search={search}
				/>
			</div>
		</main>
	)
}
