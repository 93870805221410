import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useEffect } from 'react'
import Select from 'react-select'

import Collection from 'classes/Collection'
import User from 'classes/User'
import UserList from 'classes/UserList'
import clone from 'functions/clone'
import { Option } from 'pages/ProductSaleDetail/components/Select'

interface Props {
	users: User[]
	collection: Collection
	setCollection: (input: Collection) => void
}

export default function UserListComponent(props: Props) {
	const [userList, setUserList] = useState<UserList>(new UserList())

	useEffect(() => {
		init()
	}, [])

	async function init() {
		await userList.readFromApi()
		setUserList(clone(userList))
	}

	function removeUser(id: User) {
		props.collection.removeUser(id)
		props.setCollection(clone(props.collection))
	}

	function setUser(input: Option | null) {
		if (!input?.value) return
		const user: User | undefined = userList.getById(Number(input?.value))
		if (!user) return
		props.collection.addUser(user)
		props.setCollection(clone(props.collection))
	}

	return (
		<div>
			<label className="form-label label-sm">Benutzer</label>
			<Select
				value={null}
				onChange={setUser}
				getOptionLabel={(option: Option) => option.label}
				getOptionValue={(option: Option) => option.value}
				options={userList.toOptions(props.collection.users)}
			/>
			<div style={{ marginTop: '4px' }}>
				{!props.users.length && <div>Keine Benutzer zugewiesen</div>}

				{props.users.map((e: User, i) => {
					return (
						<div
							key={i}
							style={{
								backgroundColor: 'white',
								marginTop: '1px',
								padding: '5px 8px',
							}}
						>
							<div className="d-flex justify-content-between">
								{e.getName()}
								<span
									onClick={() => removeUser(e)}
									style={{ cursor: 'pointer', color: '#aaa' }}
								>
									Entfernen
									<FontAwesomeIcon icon={faTrash} style={{ marginLeft: '7px' }} />
								</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
