import { useNavigate } from 'react-router'

import ProductSaleList from 'classes/ProductSaleList'
import { APIURL } from 'constants/urls'
import clone from 'functions/clone'

interface Props {
	productSaleList: ProductSaleList
	setProductSellList: (input: ProductSaleList) => void
	handleDelete: () => void
}

export default function ActionButtons(props: Props) {
	const navigate = useNavigate()

	function selectAll(): void {
		props.productSaleList.selectAll()
		props.setProductSellList(clone(props.productSaleList))
	}

	function unselectAll(): void {
		props.productSaleList.unselectAll()
		props.setProductSellList(clone(props.productSaleList))
	}

	function deleteProduct(): void {
		props.handleDelete()
	}

	function editAll(): void {
		const ids: number[] = props.productSaleList.getIdsOfSelected()
		const idString: string = ids.join(';')
		navigate('/product/detail?ids=' + idString)
	}

	function exportProducts(): void {
		const selected: number[] = props.productSaleList.getIdsOfSelected()
		const downloadLink: string =
			APIURL + '/product-sale-list/export-products/' + selected.join(';')
		window.location.href = downloadLink
	}

	function renderButtonDownload(): JSX.Element {
		return (
			<button
				style={styleButton}
				type="button"
				className="btn btn-outline-success btn-sm"
				onClick={exportProducts}
				disabled={props.productSaleList.getCountOfSelected() < 1}
			>
				Excel-Download
			</button>
		)
	}

	function renderButtonDelete(): JSX.Element {
		return (
			<button
				style={styleButton}
				type="button"
				className="btn btn-outline-danger btn-sm"
				onClick={deleteProduct}
				disabled={props.productSaleList.getCountOfSelected() !== 1}
			>
				Löschen
			</button>
		)
	}

	function renderButtonEditAll(): JSX.Element {
		return (
			<button
				style={styleButton}
				type="button"
				className="btn btn-outline-primary btn-sm"
				onClick={editAll}
				disabled={!(props.productSaleList.getCountOfSelected() > 1)}
			>
				Mehrere Bearbeiten
			</button>
		)
	}

	function renderButtonSelectAll(): JSX.Element {
		const check: boolean =
			props.productSaleList.getCountOfSelected() === props.productSaleList.list.length
		if (check) {
			return (
				<button
					style={styleButton}
					type="button"
					className="btn btn-outline-primary btn-sm"
					onClick={unselectAll}
				>
					Alle Abwählen
				</button>
			)
		} else {
			return (
				<button
					style={styleButton}
					type="button"
					className="btn btn-outline-primary btn-sm"
					onClick={selectAll}
				>
					Alle Anwählen
				</button>
			)
		}
	}

	return (
		<div className="d-flex" style={{ gap: '5px' }}>
			{renderButtonDownload()}
			{renderButtonDelete()}
			{renderButtonEditAll()}
			{renderButtonSelectAll()}
		</div>
	)
}

const styleButton: React.CSSProperties = {
	backgroundColor: 'white',
}
