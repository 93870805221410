import Auction from 'classes/Auction'
import AuctionList from 'classes/AuctionList'
import Search from 'pages/ProductSaleSearch/classes/Search'

interface Props {
	search: Search
	setSearch: (input: Search) => void
	auctionList: AuctionList
	handleSearch: () => Promise<void>
}

export default function AuctionSelectComponent(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		const value: number = Number(e.target.value)
		if (value === 0) {
			props.search.auction.reset()
		} else {
			props.search.auction.value = value
		}
		props.handleSearch()
	}

	function getValue() {
		if (!props.search.auction.value) {
			return '0'
		}
		return String(props.search.auction.value)
	}

	return (
		<select className="form-select" onChange={handleChange} value={getValue()}>
			<option value={0}>Alle Auktionen</option>
			{props.auctionList.all.map((e: Auction, i: number) => {
				return (
					<option value={e.id} key={i}>
						{e.id + ' – ' + e.name}
					</option>
				)
			})}
		</select>
	)
}
