import { SearchType } from 'enums/SearchType'
import { QueryParams } from 'functions/url'
import AdvancedSearchKey from 'interfaces/Search/AdvancedSearchKey'

export default class NullableSearchType implements AdvancedSearchKey {
	public id: string
	public value: SearchType | null
	public defaultValue: SearchType | null

	constructor(id: string, defaultValue: SearchType | null) {
		this.id = id
		this.value = null
		this.defaultValue = defaultValue
	}

	public reset() {
		this.value = null
	}

	public mapResponse(input: SearchType) {
		this.value = input
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				this.value = value ? (value as SearchType) : null
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.value) {
			return this.id + '=' + this.value
		}
		return null
	}

	public mapToApi(): SearchType | null {
		if (this.value === null) {
			return this.defaultValue
		}
		return this.value
	}
}
