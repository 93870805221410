import { useNavigate } from 'react-router-dom'

import { isUserAdmin } from 'functions/token'
import styles from 'pages/ProductSaleDetail/components/IdSingle/PictureEditorButton.module.scss'

interface Props {
	id: number
}

export default function PictureEditorButton({ id }: Props) {
	const navigate = useNavigate()

	if (!isUserAdmin()) return <></>
	return (
		<div
			className={styles.button}
			onClick={() => navigate(`/product/detail/picture-editor?id=${id}`)}
		>
			Bild-Editor
		</div>
	)
}
