import Collection from 'classes/Collection'
import CollectionList from 'classes/CollectionList'
import ListItem from 'pages/CollectionSearch/components/ListItem'

interface Props {
	list: CollectionList
	setList: (input: CollectionList) => void
	className?: string
}

export default function CollectionListComponent(props: Props) {
	return (
		<div className={props.className}>
			{props.list.searched.map((e: Collection, i: number) => {
				return <ListItem collection={e} key={i} />
			})}
		</div>
	)
}
