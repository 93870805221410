import axios from 'axios'
import { InternalAxiosRequestConfig } from 'axios'

import { APIURL } from 'constants/urls'

import { getToken } from './functions/token'

export const configAxios = () => {
	axios.defaults.baseURL = APIURL
	axios.defaults.timeout = 30000

	axios.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
		const token: string = getToken() ?? ''
		if (config.headers) {
			config.headers.Authorization = token
		}
		return config
	})
}
