import AuctionList from 'classes/AuctionList'
import CategoryList from 'classes/CategoryList'
import CollectionList from 'classes/CollectionList'
import ProductSale, {
	ProductSaleNumberAttribute,
	ProductSaleStringAttribute,
} from 'classes/ProductSale'
import UserList from 'classes/UserList'
import clone from 'functions/clone'
import Search from 'pages/ProductSaleDetail/classes/Search'
import CheckboxesProductSell from 'pages/ProductSaleDetail/components/CheckboxesProductSell'
import ActionButtons from 'pages/ProductSaleDetail/components/IdSingle/ActionButtons'
import HeaderInformations from 'pages/ProductSaleDetail/components/IdSingle/HeaderInformations'
import PictureEditorButton from 'pages/ProductSaleDetail/components/IdSingle/PictureEditorButton'
import PictureGallery from 'pages/ProductSaleDetail/components/ImageGallery'
import InputNumber from 'pages/ProductSaleDetail/components/InputNumber'
import InputString from 'pages/ProductSaleDetail/components/InputString'
import SelectComponent from 'pages/ProductSaleDetail/components/Select'
import TextArea from 'pages/ProductSaleDetail/components/TextArea'
import getAuctionOptionList from 'pages/ProductSaleDetail/functions/getAuctionOptionList'
import getCategoryOptionList from 'pages/ProductSaleDetail/functions/getCategoryOptionList'
import getCollectionOptionList from 'pages/ProductSaleDetail/functions/getCollectionOptionList'
import getTypeOptionList from 'pages/ProductSaleDetail/functions/getTypeOptionList'

interface Props {
	search: Search
	productSale: ProductSale
	setProductSale: (input: ProductSale) => void
	categoryList: CategoryList
	userList: UserList
	auctionList: AuctionList
	collectionList: CollectionList
	setIsLoading: (input: boolean) => void
}

export default function ProductSaleComponent(props: Props) {
	function changeType(input: string): void {
		props.productSale.type = Number(input)
		props.setProductSale(clone(props.productSale))
	}

	function changeAuction(input: string): void {
		props.productSale.auctionId = Number(input)
	}

	function changeCollection(input: string): void {
		props.productSale.collectionId = Number(input)
	}

	function changeNumber(input: number) {
		props.productSale.number = input
	}

	function changeLotNr(input: number) {
		props.productSale.lotNr = input
	}

	function createChangeNumberFunction(attribute: ProductSaleNumberAttribute) {
		return (value: number) => {
			props.productSale[attribute] = value
		}
	}

	function createChangeStringFunction(attribute: ProductSaleStringAttribute) {
		return (value: string) => {
			props.productSale[attribute] = value
			props.setProductSale(clone(props.productSale))
		}
	}

	function changeDescriptionDe(input: string) {
		props.productSale.descriptionDe = input
	}

	function changeDescriptionLongDe(input: string) {
		props.productSale.descriptionLongDe = input
	}

	function changePrice(input: number) {
		props.productSale.price = input
	}

	function changePriceProposal(input: number) {
		props.productSale.priceProposal = input
	}

	function changePriceMarket(input: number) {
		props.productSale.priceMarket = input
	}

	function changePricePurchase(input: number) {
		props.productSale.pricePurchase = input
	}

	function changeCategory(input: string) {
		props.productSale.categoryId = Number(input)
	}

	return (
		<>
			<HeaderInformations userList={props.userList} productSale={props.productSale} />
			<hr />
			<ActionButtons
				search={props.search}
				productSale={props.productSale}
				setProductSale={props.setProductSale}
				setIsLoading={props.setIsLoading}
			/>
			<PictureEditorButton id={props.productSale.id} />
			<PictureGallery imageList={props.productSale.imageList} />
			<div className="row mt-2">
				<TextArea
					label="Beschrieb individuell"
					defaultValue={props.productSale.descriptionLongDe ?? ''}
					changeValue={changeDescriptionLongDe}
					color="#deffde"
					rows={4}
				/>
			</div>
			<div className="row mt-4">
				<div className="col-4">
					<InputNumber
						label="Produkt-Nummer"
						defaultValue={props.productSale.number ?? ''}
						changeValue={changeNumber}
					/>
				</div>
				<div className="col-4">
					<InputNumber
						label="Los-Nummer"
						defaultValue={props.productSale.lotNr ?? ''}
						changeValue={changeLotNr}
					/>
				</div>
				<div className="col-4">
					<InputNumber
						label="Sortierung"
						defaultValue={props.productSale.sortOrder ?? ''}
						changeValue={createChangeNumberFunction('sortOrder')}
						color={'#cce5ff'}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-6">
					<SelectComponent
						isDisabled={props.productSale.type !== 2}
						optionList={getAuctionOptionList(props.auctionList)}
						label="Auktion"
						changeValue={changeAuction}
						defaultValue={
							props.productSale.auctionId ? String(props.productSale.auctionId) : '0'
						}
					/>
				</div>
				<div className="col-6">
					<SelectComponent
						optionList={getCollectionOptionList(props.collectionList)}
						label="Kollektion"
						changeValue={changeCollection}
						defaultValue={
							props.productSale.collectionId
								? String(props.productSale.collectionId)
								: '0'
						}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-8">
					<SelectComponent
						optionList={getCategoryOptionList(props.categoryList)}
						label="Kategorie"
						changeValue={changeCategory}
						defaultValue={
							props.productSale.categoryId
								? String(props.productSale.categoryId)
								: '0'
						}
					/>
				</div>
				<div className="col-4">
					<SelectComponent
						optionList={getTypeOptionList()}
						label="Typ"
						changeValue={changeType}
						defaultValue={props.productSale.type ? String(props.productSale.type) : '0'}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-6">
					<InputString
						label="Zumstein-Nr"
						value={props.productSale.zNr ?? ''}
						changeValue={createChangeStringFunction('zNr')}
					/>
				</div>
				<div className="col-6">
					<InputString
						label="Zumstein-Nr Nummer"
						value={props.productSale.zNrNumber ?? ''}
						changeValue={createChangeStringFunction('zNrNumber')}
						color={'#ffdede'}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<InputString
					label="Titel (deutsch)"
					value={props.productSale.nameDe ?? ''}
					changeValue={createChangeStringFunction('nameDe')}
				/>
			</div>
			<div className="row mt-2">
				<InputString
					label="Jahr"
					value={props.productSale.year ?? ''}
					changeValue={createChangeStringFunction('year')}
				/>
			</div>
			<div className="row mt-2">
				<TextArea
					label="Beschrieb"
					defaultValue={props.productSale.descriptionDe ?? ''}
					changeValue={changeDescriptionDe}
					rows={3}
				/>
			</div>
			<div className="row" style={{ marginTop: '25px' }}>
				<strong>Preise (in CHF)</strong>
				<div className="col-3">
					<InputNumber
						label="Katalogwert"
						defaultValue={props.productSale.priceProposal ?? ''}
						changeValue={changePriceProposal}
					/>
				</div>
				<div className="col-3">
					<InputNumber
						label="Verkaufspreis"
						defaultValue={props.productSale.price ?? ''}
						changeValue={changePrice}
					/>
				</div>
				<div className="col-3">
					<InputNumber
						label="Einkaufspreis"
						defaultValue={props.productSale.pricePurchase ?? ''}
						changeValue={changePricePurchase}
					/>
				</div>
				<div className="col-3">
					<InputNumber
						label="Marktpreis"
						defaultValue={props.productSale.priceMarket ?? ''}
						changeValue={changePriceMarket}
					/>
				</div>
			</div>
			<div className="row" style={{ marginTop: '25px' }}>
				<strong>Eigenschaften</strong>
				<div className="col-12 mt-2">
					<CheckboxesProductSell
						productSale={props.productSale}
						setProductSell={props.setProductSale}
					/>
				</div>
			</div>
		</>
	)
}
