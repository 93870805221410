import SearchTermFieldComponent from 'components/SearchTermField'
import { Search } from 'components/SearchTermField'

interface Props<T extends Search> {
	search: T
	setSearch: (input: T) => void
	handleSearch: () => Promise<void>
}

export default function SearchComponent<T extends Search>(props: Props<T>) {
	return (
		<div className="row">
			<div className="col-6">
				<SearchTermFieldComponent
					search={props.search}
					setSearch={props.setSearch}
					handleSearch={props.handleSearch}
				/>
			</div>
		</div>
	)
}
