import { useState } from 'react'
import { useEffect } from 'react'

import CategoryList from 'classes/CategoryList'
import Product from 'classes/Product'
import ProductList from 'classes/ProductList'
import ProductSale from 'classes/ProductSale'
import ProductSetList from 'classes/ProductSetList'
import ProductSet from 'pages/ProductSaleDetail/components/ProductSet'
import SingleProduct from 'pages/ProductSaleDetail/components/SingleProduct'

interface Props {
	categoryList: CategoryList
	productList: ProductList
	setProductList: (input: ProductList) => void
	product: Product
	setProduct: (input: Product) => void
	productSale: ProductSale
	setProductSale: (input: ProductSale) => void
	productSetList: ProductSetList
	setProductSetList: (input: ProductSetList) => void
}

export default function SourceProductDetail(props: Props) {
	const [currentTab, setCurrentTab] = useState<number>(0)

	useEffect(() => {
		initialize()
	}, [])

	async function initialize() {
		await props.productSetList.readFromApi(props.product.id)
	}

	function handleClick(input: number): void {
		if (input !== currentTab) {
			setCurrentTab(input)
		}
	}

	function handleSave(): void {
		console.info('Needs to be implemented')
	}

	return (
		<>
			<ul className="nav nav-tabs">
				<li className="nav-item">
					<div
						onClick={() => handleClick(0)}
						style={{ cursor: 'pointer' }}
						className={['nav-link', currentTab === 0 ? 'active' : undefined].join(' ')}
					>
						Produkt
					</div>
				</li>
				<li className="nav-item">
					<div
						onClick={() => handleClick(1)}
						style={{ cursor: 'pointer' }}
						className={['nav-link', currentTab === 1 ? 'active' : undefined].join(' ')}
					>
						Satz
					</div>
				</li>
			</ul>
			{currentTab === 0 && (
				<SingleProduct
					categoryList={props.categoryList}
					product={props.product}
					setProduct={props.setProduct}
					handleSave={handleSave}
					productSale={props.productSale}
					setProductSale={props.setProductSale}
				/>
			)}
			{currentTab === 1 && (
				<ProductSet
					categoryList={props.categoryList}
					productSetList={props.productSetList}
					setProductSetList={props.setProductSetList}
					productSale={props.productSale}
					setProductSale={props.setProductSale}
				/>
			)}
		</>
	)
}
