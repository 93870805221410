import User from 'classes/User'
import { propsNumber } from 'classes/User'
import { propsString } from 'classes/User'
import { propsBoolean } from 'classes/User'
import clone from 'functions/clone'
import InputNumber from 'pages/ProductSaleDetail/components/InputNumber'
import InputString from 'pages/ProductSaleDetail/components/InputString'
import InputToggle from 'pages/ProductSaleDetail/components/InputToggle'
import ActionButtons from 'pages/UserDetail/components/ActionButtons'
import ChangePasswordComponent from 'pages/UserDetail/components/ChangePassword'

interface Props {
	user: User
	setUser: (input: User) => void
	setIsLoading: (input: boolean) => void
}

export default function UserRendering(props: Props) {
	function createChangeStringFunction(userAttribute: propsString) {
		return (input: string) => {
			props.user[userAttribute] = input
			props.setUser(clone(props.user))
		}
	}

	function createChangeBooleanFunction(userAttribute: propsBoolean) {
		return (input: boolean) => {
			props.user[userAttribute] = input
			props.setUser(clone(props.user))
		}
	}

	function createChangeNumberFunction(userAttribute: propsNumber) {
		return (input: number) => {
			props.user[userAttribute] = input
		}
	}

	return (
		<>
			<ActionButtons
				user={props.user}
				setUser={props.setUser}
				setIsLoading={props.setIsLoading}
			/>

			<div className="row mt-4">
				<div className="col-4">
					<InputString
						label="Benutzername"
						value={props.user.username ?? ''}
						changeValue={createChangeStringFunction('username')}
					/>
				</div>
				<div className="col-4">
					<InputNumber
						label="Nummer"
						defaultValue={props.user.number ?? ''}
						changeValue={createChangeNumberFunction('number')}
					/>
				</div>
				<div className="col-4 d-flex justify-content-end">
					<InputToggle
						style={{ marginTop: '28px' }}
						label="Admin"
						defaultValue={props.user.isAdmin ?? undefined}
						changeValue={createChangeBooleanFunction('isAdmin')}
					/>
				</div>
				<div className="col-12">
					<InputString
						label="Vorname"
						value={props.user.firstname ?? ''}
						changeValue={createChangeStringFunction('firstname')}
					/>
					<InputString
						label="Nachname"
						value={props.user.lastname ?? ''}
						changeValue={createChangeStringFunction('lastname')}
					/>
					<InputString
						label="E-Mail"
						value={props.user.email ?? ''}
						changeValue={createChangeStringFunction('email')}
					/>
				</div>
			</div>
			<hr className="mt-4 mb-4" />
			<ChangePasswordComponent user={props.user} setUser={props.setUser} />
		</>
	)
}
