import ProductSale from 'classes/ProductSale'
import User from 'classes/User'
import UserList from 'classes/UserList'

interface Props {
	userList: UserList
	productSale: ProductSale
}

export default function HeaderInformations(props: Props) {
	function renderArticelInformation(): JSX.Element {
		const user: User | undefined = props.userList.getUserById(props.productSale.userId)
		if (user) {
			return (
				<div>
					<h3>
						<span style={{ color: 'grey' }}>Artikel</span> {user.number}.
						{('0000' + props.productSale.number).slice(-4)}
					</h3>
				</div>
			)
		}
		return <h3>Artikel</h3>
	}

	function renderUserInformations(): JSX.Element {
		const user: User | undefined = props.userList.getUserById(props.productSale.userId)
		if (user) {
			return (
				<div>
					<div className="text-end">
						<span
							style={{
								color: '#999',
								fontSize: '0.8em',
								paddingRight: '6px',
							}}
						>
							Einlieferer:
						</span>
						<strong>{user.firstname + ' ' + user.lastname}</strong>
					</div>
					<div className="text-end">
						<span
							style={{
								color: '#999',
								fontSize: '0.8em',
								paddingRight: '6px',
							}}
						>
							Kundennummer:
						</span>
						<strong>{user.number}</strong>
					</div>
				</div>
			)
		}
		return <></>
	}

	return (
		<div className="d-flex justify-content-between align-items-center">
			{renderArticelInformation()}
			{renderUserInformations()}
		</div>
	)
}
