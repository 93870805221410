interface Props {
	handleToggle: (value: boolean) => void
	sold: boolean | null
}

export default function SoldCheckbox(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const value: boolean = e.currentTarget.checked
		props.handleToggle(value)
	}

	function renderSold(input: boolean | null): JSX.Element {
		if (input) {
			return <span className="badge bg-danger">Verkauft</span>
		} else {
			return <span className="badge bg-success">Verfügbar</span>
		}
	}

	return (
		<div className="form-check">
			<input
				onChange={handleChange}
				checked={props.sold ?? false}
				className="form-check-input"
				type="checkbox"
				id="Verkauft"
			/>
			<label className="form-check-label" htmlFor={'Verkauft'}>
				{renderSold(props.sold ?? false)}
			</label>
		</div>
	)
}
