import { APIURL } from 'constants/urls'
import { APICATALOGURL } from 'constants/urls'
import ImageApi from 'interfaces/ImageApi'

export default class Image {
	public thumbnail: string | null = null
	public small: string | null = null
	public big: string | null = null
	public original: string | null = null

	public mapApiToThis(input: ImageApi, withCatalogApiUrl?: boolean) {
		let url: string = APIURL
		if (withCatalogApiUrl) url = APICATALOGURL
		if (input.thumbnail) {
			this.thumbnail = url + '/' + input.thumbnail
		}
		if (input.small) {
			this.small = url + '/' + input.small
		}
		if (input.big) {
			this.big = url + '/' + input.big
		}
		if (input.original) {
			this.original = url + '/' + input.original
		}
	}
}
