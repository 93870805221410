import clone from 'functions/clone'

interface List<Q> {
	selectAll: () => void
	unselectAll: () => void
	deleteSelectedFromApi: () => Promise<void>
	getCountOfSelected: () => number
	all: Q[]
}

interface Props<T extends List<Q>, Q> {
	list: T
	setList: (input: T) => void
	buttons: ('delete' | 'selectAll' | 'editAll')[]
}

export default function ActionButtons<T extends List<Q>, Q>(props: Props<T, Q>) {
	function selectAll(): void {
		props.list.selectAll()
		props.setList(clone(props.list))
	}

	function unselectAll(): void {
		props.list.unselectAll()
		props.setList(clone(props.list))
	}

	async function deleteSelectedAuction(): Promise<void> {
		try {
			await props.list.deleteSelectedFromApi()
			props.setList(clone(props.list))
		} catch (e: unknown) {
			console.error(e)
		}
	}

	function editAll(): void {
		console.info('TODO')
	}

	function renderButtonDelete(): JSX.Element {
		return (
			<button
				style={styleButton}
				type="button"
				className="btn btn-outline-danger btn-sm"
				onClick={deleteSelectedAuction}
				disabled={props.list.getCountOfSelected() !== 1}
			>
				Löschen
			</button>
		)
	}

	function renderButtonEditAll(): JSX.Element {
		return (
			<button
				style={styleButton}
				type="button"
				className="btn btn-outline-primary btn-sm"
				onClick={editAll}
				disabled={!(props.list.getCountOfSelected() > 1)}
			>
				Mehrere Bearbeiten
			</button>
		)
	}

	function renderButtonSelectAll(): JSX.Element {
		const check: boolean = props.list.getCountOfSelected() === props.list.all.length
		if (check) {
			return (
				<button
					style={styleButton}
					type="button"
					className="btn btn-outline-primary btn-sm"
					onClick={unselectAll}
				>
					Alle Abwählen
				</button>
			)
		} else {
			return (
				<button
					style={styleButton}
					type="button"
					className="btn btn-outline-primary btn-sm"
					onClick={selectAll}
				>
					Alle Anwählen
				</button>
			)
		}
	}

	return (
		<div className="d-flex justify-content-end mb-2" style={{ gap: '5px' }}>
			{props.buttons.includes('delete') && renderButtonDelete()}
			{props.buttons.includes('editAll') && renderButtonEditAll()}
			{props.buttons.includes('selectAll') && renderButtonSelectAll()}
		</div>
	)
}

const styleButton: React.CSSProperties = {
	backgroundColor: 'white',
}
