import { useEffect } from 'react'
import { useState } from 'react'

import LinkSale from 'classes/LinkSale'
import LinkSaleList from 'classes/LinkSaleList'
import ProductSale from 'classes/ProductSale'
import clone from 'functions/clone'
import UrlHook from 'hooks/Url'
import Search from 'pages/ProductSaleDetail/classes/Search'
import Detail from 'pages/ProductSalePictureEditor/components/PictureEditor/Detail'
import Gallery from 'pages/ProductSalePictureEditor/components/PictureEditor/Gallery'

interface Props {
	id: string
	setIsLoading: (input: boolean) => void
}

export default function PictureEditor(props: Props) {
	const [productSale, setProductSale] = useState<ProductSale>(new ProductSale())
	const [linkSaleList, setLinkSaleList] = useState<LinkSaleList>(new LinkSaleList())
	const [search, setSearch] = useState<Search>(new Search())
	const [selectedLink, setSelectedLink] = useState<LinkSale | null>(null)
	const [getUrl] = UrlHook<Search>(search)

	useEffect(() => {
		initialize()
	}, [props.id])

	async function initialize() {
		props.setIsLoading(true)
		initializeSearch()
		try {
			await productSale.readFromApi(props.id, search.mapToApi())
			setProductSale(clone(productSale))
			await linkSaleList.readFromApi(Number(props.id))
			setLinkSaleList(clone(linkSaleList))
		} finally {
			props.setIsLoading(false)
		}
	}

	function initializeSearch() {
		getUrl()
		setSearch(clone(search))
	}

	return (
		<div className="row">
			<div
				className="col-12 d-flex flex-column"
				style={{
					backgroundColor: 'white',
					paddingBottom: '12px',
					paddingTop: '15px',
					gap: '30px',
				}}
			>
				<div>
					<h3>Bild-Editor</h3>
					<hr />
					<Gallery
						linkSaleList={linkSaleList}
						setLinkSaleList={setLinkSaleList}
						selectedLink={selectedLink}
						setSelectedLink={setSelectedLink}
					/>
					{selectedLink !== null && (
						<Detail link={selectedLink} setLink={setSelectedLink} />
					)}
				</div>
			</div>
		</div>
	)
}
