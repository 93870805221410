import axios from 'axios'

import AuctionApi from 'interfaces/AuctionApi'

export async function call(id: number): Promise<AuctionApi | null> {
	try {
		await axios.delete('/auction/' + id)
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}
