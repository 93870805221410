import NullableString from 'classes/Search/NullableString'
import { QueryParams } from 'functions/url'

export default class Search {
	public searchTerm: NullableString = new NullableString('st')

	public async initialize(): Promise<void> {}

	public reset() {
		for (const value of Object.values(this)) {
			if (value.reset) {
				value.reset()
			}
		}
	}

	public mapFromUrl(input: QueryParams): void {
		for (const value of Object.values(this)) {
			if (value.mapFromUrl) {
				value.mapFromUrl(input)
			}
		}
	}

	public mapToUrl(): string | null {
		const output: (string | null)[] = []
		for (const value of Object.values(this)) {
			if (value.mapToUrl) {
				output.push(value.mapToUrl())
			}
		}
		return this.mapUrlArrayToString(output)
	}

	private mapUrlArrayToString(input: (string | null)[]): string {
		const outputFiltered: (string | null)[] = input.filter((e: string | null) => {
			if (e !== null) {
				return e
			}
		})
		return '?' + outputFiltered.join('&')
	}
}
