import * as apiGet from 'api/LinkSaleList/GetLinkSaleListByProductSaleId'
import LinkSale from 'classes/LinkSale'
import LinkSaleApi from 'interfaces/LinkSaleApi'

export default class LinkSaleList {
	public list: LinkSale[] = []

	public async readFromApi(id: number) {
		const response: LinkSaleApi[] | null = await apiGet.call(id)
		if (response) {
			this.mapFromApi(response)
		}
	}

	public mapFromApi(input: LinkSaleApi[]): void {
		this.list = input.map((e) => {
			const link: LinkSale = new LinkSale()
			link.mapFromApi(e)
			return link
		})
	}

	public addNew() {
		if (this.list.some((e) => e.id === null)) return
		const link = new LinkSale()
		link.productSaleId = this.list[0].productSaleId ?? 0
		link.type = 'Bild'
		link.userId = this.list[0].userId ?? 0
		this.list.push(link)
	}

	public changeName(name: string): void {
		const link: LinkSale | undefined = this.list.find((e) => e.id === null)
		if (link) link.name = name
	}

	public async save(): Promise<void> {
		const link: LinkSale | undefined = this.list.find((e) => e.id === null)
		if (link) await link.save()
	}
}
