import { SearchType } from 'enums/SearchType'
import clone from 'functions/clone'
import Search from 'pages/ProductSaleSearch/classes/Search'

interface Props {
	search: Search
	setSearch: (input: Search) => void
	handleSearch: () => Promise<void>
}

export default function SearchTypeSelectComponent(props: Props) {
	function handleClick(input: SearchType | null): void {
		props.search.searchType.value = input
		props.setSearch(clone(props.search))
		props.handleSearch()
	}

	return (
		<div
			className="btn-group btn-group-sm"
			role="group"
			aria-label="Basic radio toggle button group"
			style={{ backgroundColor: 'white' }}
		>
			<input
				type="radio"
				className="btn-check"
				name="btnradio"
				id="btnradio1"
				autoComplete="off"
				checked={props.search.searchType.value === null}
				onChange={() => handleClick(null)}
			/>
			<label className="btn btn-outline-primary" htmlFor="btnradio1">
				Alle Produkte
			</label>

			<input
				type="radio"
				className="btn-check"
				name="btnradio"
				id="btnradio2"
				autoComplete="off"
				checked={props.search.searchType.value === SearchType.AUCTION}
				onChange={() => handleClick(SearchType.AUCTION)}
			/>
			<label className="btn btn-outline-primary" htmlFor="btnradio2">
				Auktions-Produkte
			</label>

			<input
				type="radio"
				className="btn-check"
				name="btnradio"
				id="btnradio3"
				autoComplete="off"
				checked={props.search.searchType.value === SearchType.SHOP}
				onChange={() => handleClick(SearchType.SHOP)}
			/>
			<label className="btn btn-outline-primary" htmlFor="btnradio3">
				Shop-Produkte
			</label>
		</div>
	)
}
