import CategoryList from 'classes/CategoryList'
import { Option } from 'pages/ProductSaleDetail/components/Select'

export default function getHauserAdditionOptionList(categoryList: CategoryList): Option[] {
	const output: Option[] = categoryList.getHauserAdditionList().map((e: string) => {
		return {
			value: e,
			label: e,
		}
	})
	output.unshift({
		value: '0',
		label: 'Alle',
	})
	return output
}
