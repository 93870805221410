import axios from 'axios'

import { ProductSaleApi } from 'interfaces/ProductSaleApi'

export async function call(id: number, input: ProductSaleApi): Promise<void> {
	try {
		await axios.put('/product-sale/' + id, {
			data: input,
		})
	} catch (e: unknown) {
		console.error(e)
	}
}
