import { useNavigate } from 'react-router'

import ProductSale from 'classes/ProductSale'
import { showToast } from 'components/Toast/Toast'
import clone from 'functions/clone'
import Search from 'pages/ProductSaleDetail/classes/Search'
import SoldCheckbox from 'pages/ProductSaleDetail/components/SoldCheckbox'

interface Props {
	search: Search
	productSale: ProductSale
	setProductSale: (input: ProductSale) => void
	setIsLoading: (input: boolean) => void
}

export default function ActionButtons(props: Props) {
	const navigate = useNavigate()

	async function handlePrevClick(): Promise<void> {
		if (!props.productSale.idPrevious) return
		if (props.productSale.hasChanges()) {
			await handleSave()
		}
		props.search.id.value = props.productSale.idPrevious
		navigate('/product/detail' + props.search.mapToUrl())
	}

	async function handleNextClick(): Promise<void> {
		if (!props.productSale.idNext) return
		if (props.productSale.hasChanges()) {
			await handleSave()
		}
		props.search.id.value = props.productSale.idNext
		navigate('/product/detail' + props.search.mapToUrl())
	}

	function handleCancel(): void {
		const url: string | null = localStorage.getItem('productSearch')
		if (url) {
			localStorage.removeItem('productSearch')
			navigate(url)
		} else {
			navigate('/product/search')
		}
	}

	async function handleSave(): Promise<void> {
		props.setIsLoading(true)
		try {
			await props.productSale.writeToApi()
			showToast(200, 'Produkt wurde erfolgreich gespeichert')
		} catch (e: unknown) {
			showToast(400, 'Es ist ein Problem aufgetreten')
		} finally {
			props.setIsLoading(false)
		}
	}

	function handleToggle(input: boolean): void {
		props.productSale.sold = input
		props.setProductSale(clone(props.productSale))
	}

	return (
		<>
			<div className="mb-3 d-flex justify-content-between">
				<div>
					<button
						type="button"
						className="btn btn-primary  btn-sm"
						style={{ marginRight: '10px' }}
						onClick={handleSave}
					>
						Speichern
					</button>

					<div className="btn-group" role="group" aria-label="Basic example">
						<button
							type="button"
							className="btn btn-outline-primary  btn-sm"
							onClick={handlePrevClick}
							disabled={!props.productSale.idPrevious}
						>
							{'<'}
						</button>
						<button
							type="button"
							className="btn btn-outline-primary  btn-sm"
							onClick={handleCancel}
						>
							Abbrechen
						</button>
						<button
							type="button"
							className="btn btn-outline-primary  btn-sm"
							onClick={handleNextClick}
							disabled={!props.productSale.idNext}
						>
							{'>'}
						</button>
					</div>
				</div>
				<SoldCheckbox handleToggle={handleToggle} sold={props.productSale.sold} />
			</div>
		</>
	)
}
