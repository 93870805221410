const warningRgb = '242, 146, 22'
const dangerRgb = '220, 53, 69'
const successRgb = '40, 167, 69'
const primaryRgb = '172, 146, 89'

function getColor(input: string, alpha?: number) {
	if (!alpha) return `rgb(${input})`
	return `rgba(${input}, ${alpha})`
}

export function WARNING(alpha?: number): string {
	return getColor(warningRgb, alpha)
}

export function DANGER(alpha?: number): string {
	return getColor(dangerRgb, alpha)
}

export function SUCCESS(alpha?: number): string {
	return getColor(successRgb, alpha)
}

export function PRIMARY(alpha?: number): string {
	return getColor(primaryRgb, alpha)
}
