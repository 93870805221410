export default function NotFound(): JSX.Element {
	return (
		<div
			className={[
				'd-flex',
				'flex-column',
				'justify-content-center',
				'align-items-center',
				'text-center',
			].join(' ')}
			style={{ height: '100vh' }}
		>
			<p style={{ marginBottom: '0px', fontWeight: 'bold' }}>Fehler 404</p>
			<p style={{ marginBottom: '10px' }}>Diese Seite konnte nicht gefunden werden.</p>
		</div>
	)
}
