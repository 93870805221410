import axios from 'axios'

import { APIURL } from 'constants/urls'

export async function apiCall(username: string, password: string): Promise<ResponseData | null> {
	const url = APIURL + '/user/login'
	try {
		const requestBody: RequestBody = {
			username: username,
			password: password,
		}
		const response: Response = await axios.post(url, requestBody)
		return response.data
	} catch (e: unknown) {
		return null
	}
}

export type RequestBody = {
	username: string
	password: string
}

export interface Response {
	data: ResponseData
	status: number
}

export interface ResponseData {
	token: string
}
