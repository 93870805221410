import ProductSale from 'classes/ProductSale'
import ProductSaleList from 'classes/ProductSaleList'
import { Option } from 'pages/ProductSaleDetail/components/Select'

export default function getProductSaleOptionList(productSaleList: ProductSaleList): Option[] {
	const productSales: Option[] = productSaleList.list.map((e: ProductSale) => {
		let label: string = e.nameDe ?? '–'
		if (label === '') {
			label = '–'
		}

		return {
			value: String(e.id),
			label: e.id + ': ' + label,
		}
	})
	productSales.unshift({
		value: '0',
		label: 'Kein Highlight',
	})
	return productSales
}
