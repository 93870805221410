import ImageGallery from 'react-image-gallery'
import { ReactImageGalleryItem } from 'react-image-gallery'

import Image from 'classes/Image'
import ImageList from 'classes/ImageList'

interface Props {
	imageList: ImageList
}

export default function PictureGallery(props: Props) {
	function getImages(): ReactImageGalleryItem[] {
		return props.imageList.all.map((e: Image) => {
			return {
				original: e.big ?? '',
				thumbnail: e.thumbnail ?? '',
			}
		})
	}

	return <ImageGallery items={getImages()} showPlayButton={false} />
}
