import axios from 'axios'

import CategoryApi from 'interfaces/CategoryApi'

export async function call(): Promise<ResponseData | null> {
	try {
		const response: Response = await axios.get('/cms/category-list/all')
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: CategoryApi[]
	count: number
}
