interface Props {
	label?: string
	defaultValue: number | string
	changeValue?: (input: number) => void
	color?: string
}

export default function InputNumber(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.changeValue) {
			const value: number = parseFloat(e.currentTarget.value)
			props.changeValue(value)
		}
	}

	function renderLabel(): JSX.Element {
		if (props.label) {
			return (
				<label htmlFor="basic-url" className="form-label label-sm">
					{props.label}
				</label>
			)
		}
		return <></>
	}

	return (
		<div key={props.defaultValue}>
			{renderLabel()}
			<div className="input-group">
				<input
					type="number"
					className="form-control"
					id="basic-url"
					aria-describedby="basic-addon3"
					defaultValue={String(props.defaultValue)}
					onChange={handleChange}
					style={{ backgroundColor: props.color }}
				/>
			</div>
		</div>
	)
}
