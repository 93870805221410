interface Props {
	label?: string
	defaultValue: string
	changeValue: (input: string) => void
	color?: string
	rows: number
}

export default function TextArea(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		const value: string = e.currentTarget.value
		props.changeValue(value)
	}

	function renderLabel(): JSX.Element {
		if (props.label) {
			return (
				<label htmlFor="basic-url" className="form-label label-sm">
					{props.label}
				</label>
			)
		}
		return <></>
	}

	return (
		<div>
			{renderLabel()}
			<div className="input-group">
				<textarea
					key={props.defaultValue}
					className="form-control"
					defaultValue={String(props.defaultValue)}
					onChange={handleChange}
					rows={props.rows}
					style={{ backgroundColor: props.color }}
				/>
			</div>
		</div>
	)
}
