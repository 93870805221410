import Auction from 'classes/Auction'
import AuctionList from 'classes/AuctionList'
import { Option } from 'pages/ProductSaleDetail/components/Select'

export default function getAuctionOptionList(auctionList: AuctionList): Option[] {
	const auctions: Option[] = auctionList.all.map((e: Auction) => {
		return {
			value: String(e.id),
			label: e.name,
		}
	})
	auctions.unshift({
		value: '0',
		label: 'Keine Auktion',
	})
	return auctions
}
