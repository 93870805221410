import axios from 'axios'

import AuctionApi from 'interfaces/AuctionApi'

export async function call(input: AuctionApi): Promise<number | null> {
	try {
		const response: Response = await axios.post('/auction', {
			data: input,
		})
		return response.data.id
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: AuctionApi
}

export type ResponseData = {
	id: number
}
