import Category from 'classes/Category'
import CategoryList from 'classes/CategoryList'
import { Option } from 'pages/ProductSaleDetail/components/Select'

export default function getCategoryOptionList(categoryList: CategoryList): Option[] {
	const lastCategories: Category[] = categoryList.getLastCategories([])
	const output: Option[] = lastCategories.map((e: Category) => {
		return {
			value: String(e.id),
			label: e.fullName ?? '',
		}
	})
	output.unshift({
		value: '0',
		label: 'Keine Kategorie',
	})
	return output
}
