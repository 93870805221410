import axios from 'axios'

import { ProductSaleApi } from 'interfaces/ProductSaleApi'

export async function call(input: Request): Promise<ResponseData | null> {
	try {
		const response: Response = await axios.post('/product-sale-list/all', input)
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Request = {
	auction: number | null
	availability: Availability
	collection: number | null
	page: number
	pageCount: number
	searchTerm: string | null
	searchType: SearchType
	sortColumn: SortColumn
	sortOrder: SortOrder
	user: number | null
}

export type Availability = 'all' | 'available' | 'sold'

export type SortOrder = 'ASC' | 'DESC'

export type SortColumn =
	| 'id'
	| 'type'
	| 'znr'
	| 'year'
	| 'name'
	| 'category'
	| 'sortOrder'
	| 'price'
	| 'priceProposal'
	| 'priceMarket'
	| 'pricePurchase'

export type SearchType = 'auction' | 'shop' | null

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: ProductSaleApi[]
	count: number
}
