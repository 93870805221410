import * as apiDelete from 'api/LinkSale/DeleteLinkSale'
import * as apiPatch from 'api/LinkSale/PatchLinkSale'
import * as apiPost from 'api/LinkSale/PostLinkSale'
import * as apiPut from 'api/LinkSale/PutLinkSalePosition'
import { APIURL } from 'constants/urls'
import LinkSaleApi from 'interfaces/LinkSaleApi'
import { getSizeFromImageSizeKeys } from 'pages/ProductSalePictureEditor/components/PictureEditor/Detail'

export type ImageSizeKeys = 'thumbnail' | 'small' | 'big' | 'original'

export default class LinkSale {
	public id: number | null = null
	public name: string = ''
	public productSaleId: number = 0
	public userId: number = 0
	public position: number = 0
	public type: string = ''
	public createdAt: Date = new Date()
	public updatedAt: Date = new Date()
	public deletedAt: Date | null = null

	public mapFromApi(input: LinkSaleApi): void {
		this.id = input.id
		this.userId = input.userId
		this.name = input.name
		this.productSaleId = input.productSaleId
		this.position = input.position
		this.type = input.type
		this.createdAt = input.createdAt
		this.updatedAt = input.updatedAt
		this.deletedAt = input.deletedAt
	}

	public getUrl(sizeKey?: ImageSizeKeys) {
		if (!this.id) return ''
		const size = getSizeFromImageSizeKeys(sizeKey)
		return (
			APIURL +
			'/image/aktiv/' +
			this.userId +
			'/' +
			this.productSaleId +
			'/' +
			size +
			'/' +
			this.name
		)
	}

	public async save(): Promise<void> {
		const response: apiPost.ResponseData | null = await this.postToApi()
		if (!response) return
		this.id = response.id
		this.position = response.position
	}

	public async delete(): Promise<void> {
		await this.deleteFromApi()
		this.deletedAt = new Date()
	}

	public async restore(): Promise<void> {
		await this.patchFromApi()
		this.deletedAt = null
	}

	private async deleteFromApi(): Promise<apiDelete.ResponseData | null> {
		if (!this.id) return null
		return await apiDelete.apiCall(this.id)
	}

	private async patchFromApi(): Promise<apiPatch.ResponseData | null> {
		if (!this.id) return null
		return await apiPatch.apiCall(this.id)
	}

	private async postToApi(): Promise<apiPost.ResponseData | null> {
		return await apiPost.apiCall({
			productSaleId: this.productSaleId,
			name: this.name,
		})
	}

	public async putToApiPosition(): Promise<void> {
		console.log(this.id)
		if (!this.id) return
		await apiPut.apiCall({
			linkSaleId: this.id,
			position: this.position,
		})
	}
}
