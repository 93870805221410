import Collection from 'classes/Collection'
import { propsString } from 'classes/Collection'
import clone from 'functions/clone'
import ActionButtons from 'pages/CollectionDetail/components/ActionButtons'
import ProductList from 'pages/CollectionDetail/components/ProductListComponent'
import UserList from 'pages/CollectionDetail/components/UserListComponent'
import InputString from 'pages/ProductSaleDetail/components/InputString'

interface Props {
	collection: Collection
	setCollection: (input: Collection) => void
	setIsLoading: (input: boolean) => void
}

export default function CollectionRendering(props: Props) {
	function createChangeStringFunction(userAttribute: propsString) {
		return (input: string) => {
			props.collection[userAttribute] = input
			props.setCollection(clone(props.collection))
		}
	}

	return (
		<>
			<ActionButtons
				collection={props.collection}
				setCollection={props.setCollection}
				setIsLoading={props.setIsLoading}
			/>

			<div className="row">
				<div className="col-6">
					<div className="d-flex flex-column" style={{ gap: '20px' }}>
						<InputString
							label="Name"
							value={props.collection.name ?? ''}
							changeValue={createChangeStringFunction('name')}
						/>

						<UserList
							users={props.collection.users}
							collection={props.collection}
							setCollection={props.setCollection}
						/>
					</div>
				</div>
				<div className="col-6">
					<ProductList
						products={props.collection.products}
						collection={props.collection}
						setCollection={props.setCollection}
					/>
				</div>
			</div>
		</>
	)
}
