import React from 'react'
import { useNavigate } from 'react-router'

import User from 'classes/User'
import UserList from 'classes/UserList'

interface Props {
	userList: UserList
	setUserList: (input: UserList) => void
	className?: string
}

export default function UserListComponent(props: Props) {
	const navigate = useNavigate()

	function handleGoToClick(id: number) {
		navigate('/user/detail?id=' + id)
	}

	function getBackgroundColor(selected: boolean): string {
		if (selected) {
			return '#eee9dd'
		}
		return 'white'
	}

	function renderRole(input: boolean): JSX.Element {
		if (input) {
			return <span className="badge bg-danger">Admin</span>
		}
		return <span className="badge bg-secondary">Benutzer</span>
	}

	function renderImage() {
		return (
			<div
				style={{ height: '60px', width: '60px', backgroundColor: '#aaa' }}
				className="d-flex justify-content-center align-items-center"
			>
				<object
					data={'../img/user-solid.svg'}
					type="image/svg+xml"
					style={{ width: '30px', height: '30px' }}
				></object>
			</div>
		)
	}

	function renderWholeName(input: User): JSX.Element {
		return (
			<div>
				<span style={{ color: input.isAdmin ? '#dc3545' : undefined, marginRight: '10px' }}>
					{input.username}
				</span>
				<span style={{ color: '#aaa' }}>{input.getName()}</span>
			</div>
		)
	}

	function renderItem(input: User, key: number): JSX.Element {
		return (
			<div
				className="d-flex justify-content-between align-items-center mb-1"
				style={{
					backgroundColor: getBackgroundColor(input.selected),
					paddingRight: '20px',
					gap: '20px',
					cursor: 'pointer',
				}}
				key={key}
				onClick={() => handleGoToClick(input.id ?? 0)}
			>
				{renderImage()}

				<div className="flex-grow-1">{renderWholeName(input)}</div>

				<div style={{ width: '150px' }}>
					<div>
						<label style={labelStyle}>Nummer</label>
						{input.number}
					</div>
				</div>

				<div style={{ width: '400px' }}>
					<div>
						<label style={labelStyle}>Id</label>
						{input.id}
					</div>
					<div>
						<label style={labelStyle}>E-Mail</label>
						{input.email}
					</div>
				</div>

				<div style={{ width: '100px' }}>{renderRole(input.isAdmin)}</div>
			</div>
		)
	}
	return (
		<>
			<div className={props.className}>
				{props.userList.searched.map((e: User, i: number) => {
					return renderItem(e, i)
				})}
			</div>
		</>
	)
}

const labelStyle: React.CSSProperties = {
	color: '#999',
	fontSize: '0.8em',
	paddingRight: '6px',
}
