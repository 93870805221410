import { useEffect } from 'react'
import { useState } from 'react'

import AuctionList from 'classes/AuctionList'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import clone from 'functions/clone'
import UrlHook from 'hooks/Url'
import AuctionListComponent from 'pages/AuctionSearch/components/AuctionList'
import SearchComponent from 'pages/AuctionSearch/components/Search'

import Search from './classes/Search'

export default function OverviewAuctionPage() {
	const [auctionList, setAuctionList] = useState<AuctionList>(new AuctionList())
	const [search, setSearch] = useState<Search>(new Search())
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [getUrl, setUrl] = UrlHook<Search>(search)

	useEffect(() => {
		initialize()
	}, [])

	async function initialize() {
		getUrl()
		await auctionList.initialize()
		setAuctionList(clone(auctionList))
		await handleSearch()
	}

	async function handleSearch(): Promise<void> {
		setIsLoading(true)
		auctionList.setSearched(search.searchTerm.value ?? '')
		setAuctionList(clone(auctionList))
		setUrl()
		setIsLoading(false)
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				<h3 className="mb-4">Alle Auktionen</h3>
				<SearchComponent
					search={search}
					setSearch={setSearch}
					handleSearch={handleSearch}
				/>
				<hr />
				<AuctionListComponent auctionList={auctionList} setAuctionList={setAuctionList} />
			</div>
		</main>
	)
}
