import ProductSale from 'classes/ProductSale'
import AuctionHighlightsApi from 'interfaces/AuctionHighlightsApi'

export default class AuctionHighlights {
	public 1: null | ProductSale = null
	public 2: null | ProductSale = null
	public 3: null | ProductSale = null

	public mapToApi(): AuctionHighlightsApi {
		return {
			'1': this['1'] ? this['1'].mapToApi() : null,
			'2': this['2'] ? this['2'].mapToApi() : null,
			'3': this['3'] ? this['3'].mapToApi() : null,
		}
	}

	public mapFromApi(input: AuctionHighlightsApi) {
		if (input['1']) {
			const productSale: ProductSale = new ProductSale()
			productSale.mapFromApi(input['1'])
			this['1'] = productSale
		}
		if (input['2']) {
			const productSale: ProductSale = new ProductSale()
			productSale.mapFromApi(input['2'])
			this['2'] = productSale
		}
		if (input['3']) {
			const productSale: ProductSale = new ProductSale()
			productSale.mapFromApi(input['3'])
			this['3'] = productSale
		}
	}
}
