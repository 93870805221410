import { useEffect } from 'react'
import { useState } from 'react'

import Collection from 'classes/Collection'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import clone from 'functions/clone'
import useQuery from 'hooks/useQuery'
import CollectionRendering from 'pages/CollectionDetail/components/CollectionRendering'

export default function CollectionDetail() {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [collection, setCollection] = useState<Collection>(new Collection())

	const query = useQuery()

	useEffect(() => {
		initialize()
	}, [query])

	async function initialize() {
		const id: string | null = query.get('id')
		if (!id) return
		setIsLoading(true)
		try {
			await initializeCollection(Number(id))
		} finally {
			setIsLoading(false)
		}
	}

	async function initializeCollection(id: number) {
		await collection.apiGet(id)
		setCollection(clone(collection))
	}

	function getTitle(): string {
		const id: string | null = query.get('id')
		if (id) {
			return 'Kollektion bearbeiten'
		}
		return 'Neuer Kollektion'
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				<h3>{getTitle()}</h3>
				<hr />
				<CollectionRendering
					collection={collection}
					setCollection={setCollection}
					setIsLoading={setIsLoading}
				/>
			</div>
		</main>
	)
}
