let apiUrl = 'https://api-cms.rj-catalog.ch'
let apiUrlCatalog = 'https://api.rj-catalog.ch'

const hostname = window.location.hostname

if (hostname.includes('test-cms.rj-catalog')) {
	apiUrl = 'https://test-api-cms.rj-catalog.ch'
	apiUrlCatalog = 'https://test-api.rj-catalog.ch'
}
if (hostname.includes('localhost')) {
	apiUrl = 'http://localhost'
	apiUrlCatalog = 'https://test-api.rj-catalog.ch'
}

export const APIURL = apiUrl
export const REQUESTURL = `${apiUrl}/`
export const APICATALOGURL = `${apiUrlCatalog}/`
