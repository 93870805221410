import { useState } from 'react'

import CategoryList from 'classes/CategoryList'
import Product, { ProductNumberAttribute, ProductStringAttribute } from 'classes/Product'
import ProductSale, { ProductSaleNumberAttribute } from 'classes/ProductSale'
import ImageWithFallback from 'components/ImageWithFallback'
import { defaultImagePath } from 'constants/defaultImagePath'
import clone from 'functions/clone'
import CheckboxSourceProduct from 'pages/ProductSaleDetail/components/CheckboxesSourceProduct'
import InputNumberNew from 'pages/ProductSaleDetail/components/InputNumberNew'
import InputString from 'pages/ProductSaleDetail/components/InputString'
import Styles from 'types/Style'

import InputNumber from './InputNumber'

interface Props {
	categoryList: CategoryList
	product: Product
	setProduct: (input: Product) => void
	handleSave: () => void
	productSale: ProductSale
	setProductSale: (input: ProductSale) => void
}

export default function SingleProduct(props: Props) {
	const [price, setPrice] = useState<number>(0)
	const [priceProposal, setPricePoposal] = useState<number | undefined>(undefined)
	const [priceProposalKey, setPriceProposalKey] = useState<string | undefined>(undefined)

	function createChangeStringFunction(attribute: ProductStringAttribute) {
		return (value: string) => {
			props.product[attribute] = value
			props.setProduct(clone(props.product))
		}
	}

	function createChangeNumberFunction(attribute: ProductNumberAttribute) {
		return (value: number) => {
			props.product[attribute] = value
		}
	}

	function handleApply(): void {
		props.productSale.mapFromProduct({
			product: props.product,
			price: price,
			priceProposal: priceProposal,
			priceProposalKey: priceProposalKey,
		})
		props.setProductSale(clone(props.productSale))
	}

	function handleSave(): void {
		props.handleSave()
	}

	function renderImage(): JSX.Element {
		return (
			<ImageWithFallback
				fallback={defaultImagePath}
				src={props.product.image.big ?? ''}
				style={styles.picture}
			/>
		)
	}

	return (
		<div className="source-product" style={styles.tabContent}>
			{renderImage()}
			<div className="row mt-2">
				<div className="col-8">
					<InputString
						label="Kategorie"
						value={props.categoryList.getFullNameById(props.product.category ?? 0)}
						disabled={true}
					/>
				</div>
				<div className="col-4">
					<InputNumber
						label="Sortierung"
						defaultValue={props.product.sortOrder ?? ''}
						changeValue={createChangeNumberFunction('sortOrder')}
						color={'#cce5ff'}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-6">
					<InputString
						label="Zumstein-Nr"
						value={props.product.zNr ?? ''}
						changeValue={createChangeStringFunction('zNr')}
					/>
				</div>
				<div className="col-6">
					<InputString
						label="Zumstein-Nr Nummer"
						value={props.product.zNrNumber ?? ''}
						changeValue={createChangeStringFunction('zNrNumber')}
						color={'#ffdede'}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<InputString
					label="Titel (deutsch)"
					value={props.product.titleDe ?? props.product.nameDe ?? ''}
					changeValue={createChangeStringFunction('nameDe')}
				/>
			</div>
			<div className="row mt-2">
				<InputString
					label="Jahr"
					value={props.product.year ?? ''}
					changeValue={createChangeStringFunction('year')}
				/>
			</div>
			<div className="row mt-2">
				<InputString
					label="Beschrieb"
					value={props.product.additionalInfo10 ?? ''}
					changeValue={createChangeStringFunction('additionalInfo10')}
				/>
			</div>
			<div className="row mt-4">
				<div className="col-12">
					<CheckboxSourceProduct
						product={props.product}
						priceProposal={priceProposal}
						setPriceProposal={setPricePoposal}
						priceProposalKey={priceProposalKey}
						setPriceProposalKey={setPriceProposalKey}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<InputNumberNew label="Verkaufspreis" value={price} changeValue={setPrice} />
			</div>
			<div className="mt-4">
				<button type="button" onClick={handleApply} className="btn btn-primary">
					Übernehmen
				</button>
				<button
					type="button"
					onClick={handleSave}
					className="btn btn-danger"
					style={{ marginLeft: '10px' }}
				>
					Speichern
				</button>
			</div>
		</div>
	)
}

const styles: Styles = {
	tabContent: { backgroundColor: '#eaeaea', padding: '10px' },
	picture: {
		objectFit: 'contain',
		width: '100%',
		backgroundColor: 'black',
		height: '300px',
	},
}
