import clone from 'functions/clone'

export interface Search {
	searchTerm: {
		value: string | null
	}
}

interface Props<T extends Search> {
	search: T
	setSearch: (input: T) => void
	handleSearch: () => Promise<void>
}

export default function SearchTermFieldComponent<T extends Search>(props: Props<T>) {
	function handleChangeSearchTerm(e: React.ChangeEvent<HTMLInputElement>): void {
		const value: string = e.target.value
		const output: string | null = value === '' ? null : value
		props.search.searchTerm.value = output
		props.setSearch(clone(props.search))
	}

	function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {
		switch (e.key) {
			case 'Enter':
				props.handleSearch()
				break
		}
	}
	return (
		<div className="input-group mb-3">
			<button
				className="btn btn-outline-secondary"
				type="button"
				id="button-addon1"
				onClick={props.handleSearch}
			>
				Suchen
			</button>
			<input
				aria-describedby="button-addon1"
				aria-label="Example text with button addon"
				className="form-control"
				onChange={handleChangeSearchTerm}
				onKeyDown={handleKeyDown}
				value={props.search.searchTerm.value ?? ''}
				placeholder=""
				type="text"
			/>
		</div>
	)
}
