import * as api from 'api/CategoryList/CategoryList'
import CategoryApi from 'interfaces/CategoryApi'
import CategoryListApi from 'interfaces/CategoryListApi'

import Category from './Category'

export default class CategoryList {
	public tree: Category = new Category()
	public count: number = 0

	public constructor(input?: CategoryListApi) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public async readFromApi(): Promise<void> {
		const response: CategoryListApi | null = await api.call()
		if (response) {
			this.mapApiToThis(response)
		}
	}

	public mapApiToThis(input: CategoryListApi): void {
		this.count = input.count

		const mainCategory: Category = new Category()
		mainCategory.mapApiToThis(input.data[0])
		this.getChildsFromCategory(mainCategory, input.data)
		this.tree = mainCategory

		this.initializeFullNames(this.tree)
	}

	private initializeFullNames(category: Category): void {
		if (!category) {
			this.initializeFullNames(this.tree)
		}
		category.fullName = this.getFullNameById(category.id)
		for (const i of category.childs) {
			this.initializeFullNames(i)
		}
	}

	public getFullNameById(excelId: number): string {
		const categoryChain: Category[] = this.getCategoryChain(excelId)
		const output: string[] = categoryChain.map((e: Category) => {
			return e.name ?? ''
		})
		return output.join(' → ')
	}

	public getHauserAdditionList(): string[] {
		const output: string[] = []
		const categories: Category[] = this.getLastCategories([])

		for (const i of categories) {
			if (i.hauserAddition) {
				if (!output.includes(i.hauserAddition)) {
					output.push(i.hauserAddition)
				}
			}
		}
		return output
	}

	public getLastCategories(categoryList: Category[], category?: Category): Category[] {
		if (category === undefined) {
			return this.getLastCategories(categoryList, this.tree)
		}
		if (category.childs.length === 0) {
			categoryList.push(category)
			return categoryList
		}
		for (const i of category.childs) {
			this.getLastCategories(categoryList, i)
		}
		return categoryList
	}

	public getCategoryChain(id: number): Category[] {
		const categoryChain: Category[] | undefined = this.getCategoryChainReverse(id, [])
		if (categoryChain) {
			return categoryChain.reverse()
		}
		return []
	}

	private getCategoryChainReverse(
		idExcel: number,
		chain: Category[],
		category?: Category
	): Category[] | undefined {
		if (!category) {
			return this.getCategoryChainReverse(idExcel, chain, this.tree)
		}
		if (idExcel === category.idExcel) {
			chain.push(category)
			return chain
		}
		for (const i of category.childs) {
			const output: Category[] | undefined = this.getCategoryChainReverse(idExcel, chain, i)
			if (output) {
				chain.push(category)
				return chain
			}
		}
	}

	public getById(id: number, category?: Category): Category | undefined {
		if (!category) {
			return this.getById(id, this.tree)
		}
		if (id === category.id) {
			return category
		}
		for (const i of category.childs) {
			const output: Category | undefined = this.getById(id, i)
			if (output) {
				return output
			}
		}
	}

	private getChildsFromCategory(category: Category, all: CategoryApi[]): void {
		const childs: CategoryApi[] = all.filter((e: CategoryApi) => {
			return e.idParent === category.id
		})

		for (const i of childs) {
			const childCategory: Category = new Category()
			childCategory.mapApiToThis(i)
			this.getChildsFromCategory(childCategory, all)
			category.childs.push(childCategory)
		}
	}
}
