import styles from './styles.module.scss'

interface IProps {
	isLoading: boolean
}

export default function LoadingOverarching(props: IProps): JSX.Element {
	if (props.isLoading) {
		return (
			<div className={styles['loading-screen-overarching']}>
				<div
					className="d-flex align-items-center justify-content-center"
					style={{ height: '100%' }}
				>
					<div
						className="spinner-border"
						style={{ width: '5rem', height: '5rem' }}
						role="status"
					>
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		)
	}
	return <></>
}
