import * as api from 'api/ProductSaleList/PostProductSaleListAll'
import NullableAvailability from 'classes/Search/NullableAvailability'
import NullableNumber from 'classes/Search/NullableNumber'
import NullableSearchType from 'classes/Search/NullableSearchType'
import NullableSortColumn from 'classes/Search/NullableSortColumn'
import NullableSortOrder from 'classes/Search/NullableSortOrder'
import NullableString from 'classes/Search/NullableString'
import { defaultPageCount } from 'constants/defaultPageCount'
import { Availability } from 'enums/Availability'
import { QueryParams } from 'functions/url'
import { defaultSortColumn } from 'pages/ProductSaleSearch/constants/defaultSortColumn'
import { defaultSortOrder } from 'pages/ProductSaleSearch/constants/defaultSortOrder'

export default class Search {
	public auction: NullableNumber = new NullableNumber('auction')
	public availability: NullableAvailability = new NullableAvailability('avail', Availability.ALL)
	public collection: NullableNumber = new NullableNumber('coll')
	public page: NullableNumber = new NullableNumber('p', 0)
	public pageCount: NullableNumber = new NullableNumber('pc', defaultPageCount)
	public searchTerm: NullableString = new NullableString('st')
	public searchType: NullableSearchType = new NullableSearchType('type', null)
	public sortColumn: NullableSortColumn = new NullableSortColumn('sc', defaultSortColumn)
	public sortOrder: NullableSortOrder = new NullableSortOrder('so', defaultSortOrder)
	public user: NullableNumber = new NullableNumber('user')

	public async initialize(): Promise<void> {}

	public mapToApi(): api.Request {
		return {
			auction: this.auction.mapToApi(),
			availability: this.availability.mapToApi() as Availability,
			collection: this.collection.mapToApi(),
			page: (this.page?.mapToApi() ?? 0) + 1,
			pageCount: this.pageCount.mapToApi() ?? defaultPageCount,
			searchTerm: this.searchTerm.mapToApi(),
			searchType: this.searchType.mapToApi(),
			sortColumn: this.sortColumn.mapToApi(),
			sortOrder: this.sortOrder.mapToApi(),
			user: this.user.mapToApi(),
		}
	}

	public reset() {
		for (const value of Object.values(this)) {
			if (value.reset) {
				value.reset()
			}
		}
	}

	public mapFromUrl(input: QueryParams): void {
		for (const value of Object.values(this)) {
			if (value.mapFromUrl) {
				value.mapFromUrl(input)
			}
		}
	}

	public mapToUrl(): string | null {
		const output: (string | null)[] = []
		for (const value of Object.values(this)) {
			if (value.mapToUrl) {
				output.push(value.mapToUrl())
			}
		}
		return this.mapUrlArrayToString(output)
	}

	private mapUrlArrayToString(input: (string | null)[]): string {
		const outputFiltered: (string | null)[] = input.filter((e: string | null) => {
			if (e !== null) {
				return e
			}
		})
		return '?' + outputFiltered.join('&')
	}
}
