import { SortOrder } from 'enums/SortOrder'
import { QueryParams } from 'functions/url'
import AdvancedSearchKey from 'interfaces/Search/AdvancedSearchKey'

export default class NullableSortOrder implements AdvancedSearchKey {
	public id: string
	public value: SortOrder | null
	public defaultValue: SortOrder

	constructor(id: string, defaultValue: SortOrder) {
		this.id = id
		this.value = null
		this.defaultValue = defaultValue
	}

	public reset() {
		this.value = null
	}

	public mapResponse(input: SortOrder) {
		this.value = input
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				this.value = value ? (value as SortOrder) : null
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.value) {
			return this.id + '=' + this.value
		}
		return null
	}

	public mapToApi(): SortOrder {
		if (this.value === null) {
			return this.defaultValue
		}
		return this.value
	}
}
