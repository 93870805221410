import SearchTermFieldComponent from 'components/SearchTermField'
import Search from 'pages/UserSearch/classes/Search'

interface Props {
	search: Search
	setSearch: (input: Search) => void
	handleSearch: () => Promise<void>
}

export default function SearchComponent(props: Props) {
	return (
		<div className="row">
			<div className="col-6">
				<SearchTermFieldComponent
					search={props.search}
					setSearch={props.setSearch}
					handleSearch={props.handleSearch}
				/>
			</div>
		</div>
	)
}
