import { useNavigate } from 'react-router'

import Auction from 'classes/Auction'
import { showToast } from 'components/Toast/Toast'

interface Props {
	auction: Auction
	setAuction: (input: Auction) => void
	setIsLoading: (input: boolean) => void
}

export default function ActionButtons(props: Props) {
	const navigate = useNavigate()

	function handleCancel(): void {
		window.history.back()
	}

	async function handleSave(): Promise<void> {
		try {
			const id: number | null = await props.auction.save()
			showToast(200, 'Produkte wurden erfolgreich gespeichert')
			if (id) {
				navigate('/auction/detail?id=' + id)
			}
		} catch (e: unknown) {
			showToast(400)
		}
	}

	return (
		<div className="mb-4 d-flex justify-content-between">
			<div>
				<button
					type="button"
					className="btn btn-primary  btn-sm"
					style={{ marginRight: '10px' }}
					onClick={handleSave}
				>
					Speichern
				</button>

				<button
					type="button"
					className="btn btn-outline-primary  btn-sm"
					onClick={handleCancel}
				>
					Abbrechen
				</button>
			</div>
		</div>
	)
}
