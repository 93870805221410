import axios from 'axios'

import ProductApi from 'interfaces/ProductApi'

export async function call(id: number): Promise<ResponseData | null> {
	try {
		const response: Response = await axios.get('/product-list/set-by-product-id/' + id)
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: ProductApi[]
	count: number
}
