import * as api from 'api/ProductList/PostProductListAll'
import Product from 'classes/Product'

export default class ProductList {
	public list: Product[] = []
	public count: number = 0

	public constructor(input?: api.ResponseData) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public getById(id: number): Product | undefined {
		return this.list.find((e: Product) => {
			return e.id === id
		})
	}

	private mapApiToThis(input: api.ResponseData) {
		this.count = input.count
		this.list = []
		for (const i of input.data) {
			const sourceProduct = new Product(i)
			this.list.push(sourceProduct)
		}
	}

	public async readFromApi(input: api.Request) {
		const response: api.ResponseData | null = await api.call(input)
		if (response) {
			this.mapApiToThis(response)
		}
	}
}
