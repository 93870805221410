import { QueryParams } from 'functions/url'
import { getQueryParams } from 'functions/url'

interface Search {
	mapFromUrl: (input: QueryParams) => void
	mapToUrl: () => string | null
}

export default function UrlHook<T extends Search>(search: T) {
	function getUrl(): void {
		const queryParams: QueryParams = getQueryParams()
		search.mapFromUrl(queryParams)
	}

	function setUrl(): void {
		const url: string | null = search.mapToUrl()
		window.history.pushState(search, '', url)
	}

	return [getUrl, setUrl]
}
