import { useState } from 'react'
import { useEffect } from 'react'

import CategoryList from 'classes/CategoryList'
import ProductList from 'classes/ProductList'
import clone from 'functions/clone'
import InputString from 'pages/ProductSaleDetail/components/InputString'
import SelectComponent from 'pages/ProductSaleDetail/components/Select'
import { Option } from 'pages/ProductSaleDetail/components/Select'
import getCategoryOptionList from 'pages/ProductSaleDetail/functions/getCategoryOptionList'
import getHauserAdditionOptionList from 'pages/ProductSaleDetail/functions/getHauserAdditionOptionList'

interface Props {
	categoryList: CategoryList
	productList: ProductList
	setSourceProductList: (input: ProductList) => void
	resetSearch: () => void
	setIsLoading: (input: boolean) => void
}

export default function SourceProductSearch(props: Props) {
	const [hauserAddition, setHauserAddition] = useState<string | null>(null)
	const [zNr, setZNr] = useState<string | null>(null)
	const [year, setYear] = useState<string | null>(null)
	const [category, setCategory] = useState<number | null>(null)

	const [categoryOptions, setCategoryOptions] = useState<Option[]>([])
	const [hauserAdditionOptions, setHauserAdditionOptions] = useState<Option[]>([])

	useEffect(() => {
		initialize()
	}, [props.categoryList])

	function initialize(): void {
		setHauserAdditionOptions(getHauserAdditionOptionList(props.categoryList))
		setCategoryOptions(getCategoryOptionList(props.categoryList))
	}

	function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {
		switch (e.key) {
			case 'Enter':
				search()
				break
		}
	}

	async function search(): Promise<void> {
		props.setIsLoading(true)
		props.resetSearch()
		try {
			await props.productList.readFromApi({
				hauserAddition: hauserAddition,
				zNr: zNr,
				year: year,
				category: category,
			})
			props.setSourceProductList(clone(props.productList))
		} finally {
			props.setIsLoading(false)
		}
	}

	function handleHauserAdditionChange(input: string): void {
		if (input !== '0') {
			setHauserAddition(input)
		} else {
			setHauserAddition('')
		}
	}

	function handleZNrChange(input: string): void {
		setZNr(input)
	}

	function handleYearChange(input: string): void {
		setYear(input)
	}

	function handleCategoryChange(input: string): void {
		if (input !== '0') {
			setCategory(Number(input))
		} else {
			setCategory(null)
		}
	}

	return (
		<>
			<div className="row source-product">
				<div className="col-4">
					<SelectComponent
						label="Hauser-Zusatz"
						optionList={hauserAdditionOptions}
						defaultValue={'0'}
						changeValue={handleHauserAdditionChange}
					/>
				</div>
				<div className="col-4">
					<InputString
						label="Z-Nr."
						placeholder="Z-Nr. eingeben"
						changeValue={handleZNrChange}
						onKeyDown={handleKeyDown}
					/>
				</div>
				<div className="col-4">
					<InputString
						label="Jahr"
						placeholder="Jahr eingeben"
						changeValue={handleYearChange}
						onKeyDown={handleKeyDown}
					/>
				</div>
				<div className="col-12 mt-2">
					<SelectComponent
						label="Kategorie"
						optionList={categoryOptions}
						defaultValue={'0'}
						changeValue={handleCategoryChange}
					/>
				</div>
				<div className="col-12 mt-3">
					<button type="button" className="btn btn-primary btn-sm" onClick={search}>
						Suchen
					</button>
				</div>
			</div>
		</>
	)
}
