export default function removeDuplicatedStrings(input: string[]): string[] {
	input.sort()
	const output: string[] = []
	for (const i of input) {
		if (!output.includes(i)) {
			output.push(i)
		}
	}
	return output
}
