import * as api from 'api/ProductSaleList/PostProductSaleListAll'
import Auction from 'classes/Auction'
import AuctionList from 'classes/AuctionList'
import Category from 'classes/Category'
import CategoryList from 'classes/CategoryList'
import ProductSale from 'classes/ProductSale'
import removeDuplicatedStrings from 'functions/removeDuplicatedStrings'

export default class ProductSaleList {
	public list: ProductSale[] = []
	public auctionList: AuctionList = new AuctionList()
	public categoryList: CategoryList = new CategoryList()
	public count: number = 0

	public async initialize() {
		await this.auctionList.readFromApi()
		await this.categoryList.readFromApi()
	}

	public async readFromApi(input: api.Request) {
		const response: api.ResponseData | null = await api.call(input)
		if (response) {
			this.mapApiToThis(response)
		}
	}

	public async readFromApiWithIds(ids: string[]) {
		this.list = []
		for (const id of ids) {
			const product = new ProductSale()
			await product.readFromApi(id)
			this.list.push(product)
		}
		this.count = this.list.length
	}

	public async deleteSelectedFromApi() {
		const selectedProducts: ProductSale[] = this.getSelected()
		if (selectedProducts.length > 1) return
		if (selectedProducts.length === 0) return
		selectedProducts[0].deleteFromApi()
	}

	public selectAll(): void {
		for (const i of this.list) {
			i.selected = true
		}
	}

	public unselectAll(): void {
		for (const i of this.list) {
			i.selected = false
		}
	}

	public getSelected(): ProductSale[] {
		return this.list.filter((e: ProductSale) => {
			if (e.selected === true) {
				return e
			}
		})
	}

	public getNumberOfValuesString(): string {
		if (this.list.length > 1) {
			return this.list.length + ' Werte'
		}
		if (this.list.length === 1) {
			return this.list.length + ' Wert'
		}
		return 'Keine Werte'
	}

	public getAllTypesString(): string {
		if (!this.list[0]) return '0'

		const output: number | null = this.list[0].type
		if (output === null) return '0'

		for (const i of this.list) {
			if (i.type !== output) {
				return '0'
			}
		}
		return String(output)
	}

	public getAllAuctionsString(): string {
		if (!this.list[0]) return '0'

		const output: number | null = this.list[0].auctionId
		if (output === null) return '0'

		for (const i of this.list) {
			if (i.auctionId !== output) {
				return '0'
			}
		}
		return String(output)
	}

	public getAllCollectionsString(): string {
		if (!this.list[0]) return '0'

		const output: number | null = this.list[0].collectionId
		if (output === null) return '0'

		for (const i of this.list) {
			if (i.collectionId !== output) {
				return '0'
			}
		}
		return String(output)
	}

	public getAllZnrString(): string {
		if (!this.list[0]) return ''
		const output: string[] = []
		for (const i of this.list) {
			if (i.zNr) output.push(i.zNr)
		}
		if (output.length > 0) {
			return removeDuplicatedStrings(output).join(', ')
		} else {
			return this.getNumberOfValuesString()
		}
	}

	public getAllYearString(): string {
		if (!this.list[0]) return ''
		const output: string[] = []
		for (const i of this.list) {
			if (i.year) output.push(i.year)
		}
		if (output.length > 0) {
			return removeDuplicatedStrings(output).join(', ')
		} else {
			return this.getNumberOfValuesString()
		}
	}

	public getAllZnrNumberString(): string {
		if (!this.list[0]) return ''
		const output: string[] = []
		for (const i of this.list) {
			if (i.zNrNumber) output.push(i.zNrNumber)
		}
		if (output.length > 0) {
			return removeDuplicatedStrings(output).join(', ')
		} else {
			return this.getNumberOfValuesString()
		}
	}

	public getIdsOfSelected(): number[] {
		const productSaleList: ProductSale[] = this.getSelected()
		return productSaleList.map((e: ProductSale) => {
			return e.id
		})
	}

	public setAllTypes(input: number) {
		for (const i of this.list) {
			i.type = Number(input)
		}
	}

	public setAllAuctionIds(input: number) {
		for (const i of this.list) {
			i.auctionId = Number(input)
		}
	}

	public setAllCollectionIds(input: number) {
		for (const i of this.list) {
			i.collectionId = Number(input)
		}
	}

	public setAllNumbers(input: number) {
		for (const i of this.list) {
			i.number = Number(input)
		}
	}

	public setAllPrices(input: number) {
		for (const i of this.list) {
			i.price = input
		}
	}

	public setAllPriceProposals(input: number) {
		for (const i of this.list) {
			i.priceProposal = input
		}
	}

	public setAllSoldBooleans(input: boolean) {
		for (const i of this.list) {
			i.sold = input
		}
	}

	public setAllZNrs(input: string) {
		for (const i of this.list) {
			i.zNr = input
		}
	}

	public setAllZNrNumbers(input: string) {
		for (const i of this.list) {
			i.zNrNumber = input
		}
	}

	public setAllNames(input: string) {
		for (const i of this.list) {
			i.nameDe = input
		}
	}

	public setAllYears(input: string) {
		for (const i of this.list) {
			i.year = input
		}
	}

	public getCountOfSelected(): number {
		const check: ProductSale[] = this.getSelected()
		return check.length
	}

	public toggleSelectById(id: number): void {
		const productSell: ProductSale | undefined = this.getById(id)
		if (productSell) {
			productSell.selected = !productSell.selected
		}
	}

	public getById(id: number): ProductSale | undefined {
		return this.list.find((e: ProductSale) => {
			return e.id === id
		})
	}

	public mapApiToThis(input: api.ResponseData): void {
		this.count = input.count
		this.list = []
		for (const i of input.data) {
			const productSell = new ProductSale()
			productSell.mapFromApi(i)
			this.list.push(productSell)
		}
		this.mapAuctionToAll()
		this.mapCategoryToAll()
	}

	private mapAuctionToAll() {
		for (const i of this.list) {
			const auction: Auction | undefined = this.auctionList.getById(i.auctionId)
			if (auction) {
				i.auction = auction
			}
		}
	}

	private mapCategoryToAll() {
		for (const i of this.list) {
			const category: Category | undefined = this.categoryList.getById(i.categoryId ?? 0)
			if (category) {
				i.category = category
			}
		}
	}
}
