import axios from 'axios'

import AuctionListApi from 'interfaces/AuctionListApi'

export async function call(): Promise<AuctionListApi | null> {
	try {
		const response: Response = await axios.get('/auction-list/all')
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: AuctionListApi
}

export type ResponseData = {
	data: AuctionListApi[]
}
