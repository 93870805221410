import { useState } from 'react'
import { useEffect } from 'react'

import Collection from 'classes/Collection'
import ProductSale from 'classes/ProductSale'
import ProductSaleList from 'classes/ProductSaleList'
import clone from 'functions/clone'

interface Props {
	products: string[]
	collection: Collection
	setCollection: (input: Collection) => void
}

export default function ProductListComponent(props: Props) {
	const [productList, setProductList] = useState<ProductSaleList>(new ProductSaleList())

	useEffect(() => {
		init()
	}, [props.products])

	async function init() {
		await productList.readFromApiWithIds(props.products)
		setProductList(clone(productList))
	}

	return (
		<div>
			<label className="form-label label-sm">Produkte</label>
			<ol style={{ marginTop: '4px' }}>
				{!productList.list.length && <div>Keine Produkte zugewiesen</div>}
				{productList.list.map((e: ProductSale, i: number) => {
					return <li key={i}>{e.nameDe}</li>
				})}
			</ol>
		</div>
	)
}
