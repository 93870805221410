interface Props {
	label: string
	style?: React.CSSProperties
	defaultValue?: boolean
	changeValue?: (input: boolean) => void
	color?: string
	disabled?: boolean
}

export default function InputToggle(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.changeValue) {
			const value: boolean = e.currentTarget.checked
			props.changeValue(value)
		}
	}

	return (
		<div className="form-check form-switch" style={props.style}>
			<input
				onChange={handleChange}
				checked={props.defaultValue ?? false}
				className="form-check-input"
				type="checkbox"
				id={props.label}
			/>
			<label className="form-check-label" htmlFor={props.label}>
				{props.label}
			</label>
		</div>
	)
}
