import { useState } from 'react'

import User from 'classes/User'
import InputPassword from 'components/Inputs/InputPassword'

interface Props {
	user: User
	setUser: (input: User) => void
}

export default function ChangePasswordComponent(props: Props) {
	const [newPassword, setNewPassword] = useState<string | null>(null)
	const [repeatedNewPassword, setRepeatedNewPassword] = useState<string | null>(null)
	const [isValid, setIsValid] = useState<boolean | undefined>(undefined)

	function onBlur() {
		if (newPassword && repeatedNewPassword) {
			if (newPassword === repeatedNewPassword) {
				props.user.password = newPassword
				setIsValid(true)
			} else {
				props.user.password = ''
				setIsValid(false)
			}
		}
	}

	return (
		<div className="row">
			{' '}
			<h5>Passwort ändern</h5>
			<div className="col-12">
				<InputPassword
					label="Neues Password"
					defaultValue={''}
					changeValue={setNewPassword}
					onBlur={onBlur}
					isValid={isValid}
				/>
			</div>
			<div className="col-12">
				<InputPassword
					label="Neues Password erneut eingeben"
					defaultValue={''}
					changeValue={setRepeatedNewPassword}
					onBlur={onBlur}
					isValid={isValid}
				/>
			</div>
		</div>
	)
}
