import CategoryApi from '../interfaces/CategoryApi'

export default class Category {
	public id: number = 0
	public idExcel: number | null = null
	public idParent: number | null = null
	public sortOrder: number | null = null
	public name: string | null = null
	public fullName: string | null = null
	public hauserAddition: string | null = null
	public childs: Category[] = []

	public constructor(input?: CategoryApi) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public mapApiToThis(input: CategoryApi) {
		this.id = input.id
		this.idExcel = input.idExcel
		this.idParent = input.idParent
		this.sortOrder = input.sortOrder
		this.name = input.name
		this.hauserAddition = input.hauserAddition
	}
}
