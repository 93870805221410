import { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'

export type Option = {
	value: string
	label: string
}

interface Props {
	label: string
	optionList: Option[]
	defaultValue: string
	changeValue: (input: string) => void
	isDisabled?: boolean
}

export default function SelectComponent(props: Props) {
	const [value, setValue] = useState<Option | null>(null)

	useEffect(() => {
		setValue({
			value: props.defaultValue,
			label: findDefaultLabel(props.defaultValue),
		})
	}, [props.defaultValue, props.optionList])

	function findDefaultLabel(defaultValue: string): string {
		const defaultOption: Option | undefined = props.optionList.find((e: Option) => {
			return e.value === defaultValue
		})
		if (defaultOption) {
			return defaultOption.label
		}
		return ''
	}

	function handleChange(option: Option | null) {
		if (option) {
			setValue(option)
			props.changeValue(option.value)
		}
	}

	return (
		<div>
			<label className="form-label label-sm">{props.label}</label>
			<Select
				isDisabled={props.isDisabled}
				value={value}
				onChange={handleChange}
				getOptionLabel={(option: Option) => option.label}
				getOptionValue={(option: Option) => option.value}
				options={props.optionList}
			/>
		</div>
	)
}
