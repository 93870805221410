import { useEffect } from 'react'
import { useState } from 'react'

import ProductSetList from 'classes/ProductSetList'
import renderLabel from 'pages/ProductSaleDetail/functions/renderLabel'
import CheckboxElement from 'pages/ProductSaleDetail/types/CheckboxElement'

interface Props {
	productSetList: ProductSetList
	priceProposal: number | undefined
	setPriceProposal: (input: number | undefined) => void
	setPriceProposalKey: (input: string | undefined) => void
	price: number | undefined
	setPrice: (input: number) => void
}

const checkboxes: CheckboxElement[] = [
	{ key: 'stamped', label: 'Gestempelt' },
	{ key: 'mintNeverHinged', label: 'Postfrisch' },
	{ key: 'unstamped', label: 'Ungebraucht' },
	{ key: 'letter', label: 'Brief' },
	{ key: 'fdc', label: 'FDC' },
	{ key: 'block', label: 'Viererblock, Gestempelt' },
	{ key: 'blockMintNeverHinged', label: 'Viererblock Postfrisch' },
	{ key: 'fullstampFirstDay', label: 'Vollstempel ET' },
	{ key: 'firstDayBlock', label: 'Viererblock ET' },
]

export default function CheckboxesProductSet(props: Props) {
	const [checkboxElements, setCheckboxElements] = useState<CheckboxElement[]>([])

	useEffect(() => {
		initialize()
	}, [])

	function initialize(): void {
		const output: CheckboxElement[] = []
		for (const i of checkboxes) {
			output.push({
				key: i.key,
				label: renderLabel(i.label, props.productSetList.getPriceString(i.key)),
				checked: false,
				price: props.productSetList.getPrice(i.key),
			})
		}
		setCheckboxElements(output)
	}

	function handleChangeCheckbox(key: string): void {
		checkboxElements.forEach((e: CheckboxElement) => {
			if (e.key !== key) {
				e.checked = false
			} else {
				e.checked = !e.checked
				props.setPriceProposal(e.price)
				if (e.checked) {
					props.setPriceProposalKey(key)
				} else {
					props.setPriceProposalKey(undefined)
				}
			}
		})
		setCheckboxElements([...checkboxElements])
	}

	function renderCheckboxes(input: CheckboxElement[]) {
		return (
			<>
				{input.map((e: CheckboxElement, i: number) => {
					return (
						<div key={i}>
							<div className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									checked={e.checked}
									id={e.key}
									onChange={() => handleChangeCheckbox(e.key)}
								/>
								<label className="form-check-label" htmlFor={e.key}>
									{e.label}
								</label>
							</div>
						</div>
					)
				})}
			</>
		)
	}

	return (
		<div className="row">
			<div className="col-6">{renderCheckboxes(checkboxElements.slice(0, 5))}</div>
			<div className="col-6">{renderCheckboxes(checkboxElements.slice(5))}</div>
		</div>
	)
}
