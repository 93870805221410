export type StatusElementInformations = {
	title: string
	defaultMessage: string
	icon: 'success' | 'error' | 'warning' | 'info' | 'question'
	colors: {
		icon: string
		light: string
		dark: string
	}
}

export type StatusElementType = {
	success: StatusElementInformations
	warning: StatusElementInformations
	danger: StatusElementInformations
}

export const StatusElement: StatusElementType = {
	success: {
		title: 'OK',
		defaultMessage: 'API-Request war erfolgreich',
		icon: 'success',
		colors: {
			icon: '#198754',
			light: 'rgba(209, 231, 221, 0.3)',
			dark: 'rgba(209, 231, 221, 1.0)',
		},
	},
	warning: {
		title: 'Warnung',
		defaultMessage: 'Es ist ein Problem aufgetreten',
		icon: 'warning',
		colors: {
			icon: '#ffc107',
			light: 'rgba(255, 243, 205, 0.3)',
			dark: 'rgba(255, 243, 205, 1.0)',
		},
	},
	danger: {
		title: 'Error',
		defaultMessage: 'Es ist ein Problem aufgetreten',
		icon: 'error',
		colors: {
			icon: '#dc3545',
			light: 'rgba(248, 215, 218, 0.3)',
			dark: 'rgba(248, 215, 218, 1.0)',
		},
	},
}
