import { useEffect } from 'react'
import { useState } from 'react'

import User from 'classes/User'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import clone from 'functions/clone'
import useQuery from 'hooks/useQuery'
import UserRendering from 'pages/UserDetail/components/UserRendering'

export default function UserDetail() {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [user, setUser] = useState<User>(new User())

	const query = useQuery()

	useEffect(() => {
		initialize()
	}, [query])

	async function initialize() {
		const id: string | null = query.get('id')
		if (!id) return
		setIsLoading(true)
		try {
			await initializeUser(Number(id))
		} finally {
			setIsLoading(false)
		}
	}

	async function initializeUser(id: number) {
		await user.apiGetById(id)
		setUser(clone(user))
	}

	function getTitle(): string {
		const id: string | null = query.get('id')
		if (id) {
			return 'User bearbeiten'
		}
		return 'Neuer User'
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				<div className="row">
					<div className="col-6 offset-3">
						<h3>{getTitle()}</h3>
						<hr />
						<UserRendering user={user} setUser={setUser} setIsLoading={setIsLoading} />
					</div>
				</div>
			</div>
		</main>
	)
}
