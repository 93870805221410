import axios from 'axios'

import UserApi from 'interfaces/UserApi'

export async function call(id: number): Promise<UserApi | null> {
	try {
		const response: Response = await axios.get('/user/' + id)
		return response.data.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: UserApi
}
