import { useEffect } from 'react'
import { useState } from 'react'

import CategoryList from 'classes/CategoryList'
import Product from 'classes/Product'
import ProductList from 'classes/ProductList'
import ProductSale from 'classes/ProductSale'
import ProductSetList from 'classes/ProductSetList'
import SourceProductDetail from 'pages/ProductSaleDetail/components/SourceProductDetail'
import SourceProductSearch from 'pages/ProductSaleDetail/components/SourceProductSearch'
import SourceProductSearchList from 'pages/ProductSaleDetail/components/SourceProductSearchList'

interface Props {
	productSale: ProductSale
	setProductSell: (input: ProductSale) => void
	categoryList: CategoryList
}

export default function SourceProductComponent(props: Props) {
	const [productList, setProductList] = useState<ProductList>(new ProductList())
	const [product, setProduct] = useState<Product | undefined>(undefined)
	const [productSetList, setProductSetList] = useState<ProductSetList>(new ProductSetList())
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		getLocalStorageProduct()
	}, [])

	function resetSearch(): void {
		setProduct(undefined)
		localStorage.removeItem('productId')
	}

	function setProductToStateAndLocalStorage(input: Product | undefined): void {
		setProduct(input)
		const zNr: string | null = input?.zNr ?? null
		const id: number | null = input?.id ?? null
		if (id && zNr) {
			localStorage.setItem('productId', id + '::' + zNr)
		}
	}

	async function getLocalStorageProduct(): Promise<void> {
		const productId: string | null = localStorage.getItem('productId')
		if (productId) {
			const split: string[] = productId.split('::')
			const id: number = Number(split[0])
			const zNr: string = split[1]
			await productList.readFromApi({
				hauserAddition: null,
				zNr: zNr,
				year: null,
				category: null,
			})
			const product: Product | undefined = productList.getById(id)
			if (product) setProduct(product)
		}
	}

	return (
		<>
			<h3>Quell-Produkt</h3>
			<hr />
			<SourceProductSearch
				categoryList={props.categoryList}
				setIsLoading={setIsLoading}
				productList={productList}
				setSourceProductList={setProductList}
				resetSearch={resetSearch}
			/>
			<hr />
			{product && productSetList ? (
				<SourceProductDetail
					categoryList={props.categoryList}
					productList={productList}
					setProductList={setProductList}
					product={product}
					setProduct={setProduct}
					productSale={props.productSale}
					setProductSale={props.setProductSell}
					productSetList={productSetList}
					setProductSetList={setProductSetList}
				/>
			) : (
				<SourceProductSearchList
					isLoading={isLoading}
					categoryList={props.categoryList}
					productList={productList}
					setProduct={setProductToStateAndLocalStorage}
				/>
			)}
		</>
	)
}
