import axios from 'axios'

import { ProductSaleApi } from 'interfaces/ProductSaleApi'

export async function call(id: string, body?: Request): Promise<ProductSaleApi | null> {
	try {
		const response: Response = await axios.post('/product-sale/get-by-id/' + id, body)
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: ProductSaleApi
}

export type Request = {
	userId: number | null
	auctionId?: number | null
	availability: string | null
}
