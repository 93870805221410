interface Props {
	label?: string
	defaultValue?: Date
	changeValue?: (input: Date) => void
	color?: string
	disabled?: boolean
}

export default function InputDate(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.changeValue) {
			const value: string = e.currentTarget.value
			const date: Date = new Date(value)
			props.changeValue(date)
		}
	}

	function getInputDate(): string | undefined {
		if (props.defaultValue) {
			return props.defaultValue.toJSON().slice(0, 10)
		}
		return undefined
	}

	function renderLabel(): JSX.Element {
		if (props.label) {
			return (
				<label htmlFor={props.label} className="form-label label-sm">
					{props.label}
				</label>
			)
		}
		return <></>
	}

	return (
		<div>
			{renderLabel()}
			<div className="input-group">
				<input
					type="date"
					className="form-control"
					id={props.label}
					defaultValue={getInputDate()}
					onChange={handleChange}
					style={{ backgroundColor: props.color }}
					disabled={props.disabled}
				/>
			</div>
		</div>
	)
}
