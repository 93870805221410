import Collection from 'classes/Collection'
import CollectionList from 'classes/CollectionList'
import { Option } from 'pages/ProductSaleDetail/components/Select'

export default function getCollectionOptionList(list: CollectionList): Option[] {
	const options: Option[] = list.all.map((e: Collection) => {
		return {
			value: String(e.id),
			label: e.name,
		}
	})
	options.unshift({
		value: '0',
		label: 'Keine Kollektion',
	})
	return options
}
