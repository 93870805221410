import Swal from 'sweetalert2'

import { StatusElement } from 'components/Toast/StatusElement'
import { StatusElementInformations } from 'components/Toast/StatusElement'

function getStatusElement(status: number): StatusElementInformations {
	switch (status) {
		case 200:
			return StatusElement.success
		case 400:
			return StatusElement.danger
		default:
			return StatusElement.warning
	}
}

export async function showToast(status: number, message?: string, milliseconds?: number) {
	const statusElement: StatusElementInformations = getStatusElement(status)
	Swal.fire({
		position: 'top-end',
		icon: statusElement.icon,
		title: statusElement.title,
		text: message ?? statusElement.defaultMessage,
		showConfirmButton: false,
		timer: milliseconds ?? 1500,
	})
}
