import * as api from 'api/ProductSale/GetProductSaleById'
import NullableAvailability from 'classes/Search/NullableAvailability'
import NullableNumber from 'classes/Search/NullableNumber'
import { Availability } from 'enums/Availability'
import { QueryParams } from 'functions/url'

export default class Search {
	public id: NullableNumber = new NullableNumber('id')
	public auctionId: NullableNumber = new NullableNumber('auction')
	public userId: NullableNumber = new NullableNumber('user')
	public availability: NullableAvailability = new NullableAvailability('avail')

	public async initialize(): Promise<void> {}

	public mapToApi(): api.Request {
		return {
			auctionId: this.auctionId.mapToApi(),
			userId: this.userId.mapToApi(),
			availability: this.availability.mapToApi() as Availability,
		}
	}

	public reset() {
		for (const value of Object.values(this)) {
			if (value.reset) {
				value.reset()
			}
		}
	}

	public mapFromUrl(input: QueryParams): void {
		for (const value of Object.values(this)) {
			if (value.mapFromUrl) {
				value.mapFromUrl(input)
			}
		}
	}

	public mapToUrl(): string | null {
		const output: (string | null)[] = []
		for (const value of Object.values(this)) {
			if (value.mapToUrl) {
				output.push(value.mapToUrl())
			}
		}
		return this.mapUrlArrayToString(output)
	}

	private mapUrlArrayToString(input: (string | null)[]): string {
		const outputFiltered: (string | null)[] = input.filter((e: string | null) => {
			if (e !== null) {
				return e
			}
		})
		return '?' + outputFiltered.join('&')
	}
}
