import { useNavigate } from 'react-router'

import Collection from 'classes/Collection'
import { showToast } from 'components/Toast/Toast'

interface Props {
	collection: Collection
	setCollection: (input: Collection) => void
	setIsLoading: (input: boolean) => void
}

export default function ActionButtons(props: Props) {
	const navigate = useNavigate()

	function handleCancel(): void {
		window.history.back()
	}

	async function handleSave(): Promise<void> {
		try {
			const response: {
				status: number
				id: number | null
			} = await props.collection.save()
			showHandleSaveToast(response.status)
			if (response.id) navigate('/user/detail?id=' + response.id)
		} catch (e: unknown) {
			showHandleSaveToast(400)
		}
	}

	return (
		<div className="mb-4 d-flex justify-content-between">
			<div>
				<button
					type="button"
					className="btn btn-primary  btn-sm"
					style={{ marginRight: '10px' }}
					onClick={handleSave}
				>
					Speichern
				</button>

				<button
					type="button"
					className="btn btn-outline-primary  btn-sm"
					onClick={handleCancel}
				>
					Abbrechen
				</button>
			</div>
		</div>
	)
}

function showHandleSaveToast(status: number): void {
	switch (status) {
		case 200:
			showToast(200, 'Kollektion wurde erfolgreich gespeichert')
			break
		case 404:
			showToast(400, 'Status 404 ist aufgetreten')
			break
		default:
			showToast(400, 'Ein Fehler ist aufgetreten')
	}
}
