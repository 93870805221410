import { useEffect } from 'react'
import { useState } from 'react'

import CollectionList from 'classes/CollectionList'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import clone from 'functions/clone'
import UrlHook from 'hooks/Url'
import Search from 'pages/CollectionSearch/classes/Search'
import CollectionListComponent from 'pages/CollectionSearch/components/CollectionList'
import SearchComponent from 'pages/CollectionSearch/components/Search'

export default function CollectionSearch() {
	const [collectionList, setCollectionList] = useState<CollectionList>(new CollectionList())
	const [search, setSearch] = useState<Search>(new Search())
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [getUrl, setUrl] = UrlHook<Search>(search)

	useEffect(() => {
		initialize()
	}, [])

	async function initialize() {
		getUrl()
		await collectionList.initialize()
		setCollectionList(clone(collectionList))
		await handleSearch()
	}

	async function handleSearch(): Promise<void> {
		setIsLoading(true)
		collectionList.setSearched(search.searchTerm.value ?? '')
		setCollectionList(clone(collectionList))
		setUrl()
		setIsLoading(false)
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				<h3 className="mb-4">Alle Kollektionen</h3>
				<SearchComponent
					search={search}
					setSearch={setSearch}
					handleSearch={handleSearch}
				/>
				<hr />
				<CollectionListComponent list={collectionList} setList={setCollectionList} />
			</div>
		</main>
	)
}
