import axios from 'axios'

import ProductApi from 'interfaces/ProductApi'

export async function call(input: Request): Promise<ResponseData | null> {
	try {
		const response: Response = await axios.post('/product-list/all', input)
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Request = {
	hauserAddition: string | null
	zNr: string | null
	year: string | null
	category: number | null
}

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: ProductApi[]
	count: number
}
