import * as apiDelete from 'api/ProductSale/DeleteProductSale'
import * as apiGet from 'api/ProductSale/GetProductSaleById'
import * as apiPut from 'api/ProductSale/PutProductSale'
import Auction from 'classes/Auction'
import Category from 'classes/Category'
import ImageList from 'classes/ImageList'
import Product from 'classes/Product'
import ProductSetList from 'classes/ProductSetList'
import { ProductSaleApi } from 'interfaces/ProductSaleApi'
import CheckboxElement from 'types/CheckboxElement'

export type ProductSaleStringAttribute = 'zNrNumber' | 'zNr' | 'nameDe' | 'year'
export type ProductSaleNumberAttribute = 'sortOrder'

export type CheckboxKeys =
	| 'stamped'
	| 'fullStamped'
	| 'letter'
	| 'letterExcerpt'
	| 'firstday'
	| 'mintNeverHinged'
	| 'unused'
	| 'unusedWithoutRubber'
	| 'blockOfFour'
	| 'unit'
	| 'luxus'
	| 'rarity'

export default class ProductSale {
	public id: number = 0
	public sortOrder: number | null = null
	public idNext: number | null = null
	public idPrevious: number | null = null
	public number: number | null = null
	public auctionId: number | null = null
	public collectionId: number | null = null
	public auction: Auction | null = null
	public lotNr: number | null = null
	public setNr: string | null = null
	public zNr: string | null = null
	public zNrNumber: string | null = null
	public miNr: string | null = null
	public nameDe: string | null = null
	public nameFr: string | null = null
	public year: string | null = null
	public valueLevel: string | null = null
	public valueLevelCurrency: string | null = null
	public descriptionDe: string | null = null
	public descriptionFr: string | null = null
	public descriptionLongDe: string | null = null
	public descriptionLongFr: string | null = null
	public tagsDe: string | null = null
	public tagsFr: string | null = null
	public issueDate: string | null = null
	public userId: number | null = null
	public userNumber: number | null = null
	public categoryId: number | null = null
	public category: Category | null = null
	public stamped: boolean = false
	public fullStamped: boolean = false
	public letter: boolean = false
	public letterExcerpt: boolean = false
	public firstday: boolean = false
	public mintNeverHinged: boolean = false
	public unused: boolean = false
	public unusedWithoutRubber: boolean = false
	public blockOfFour: boolean = false
	public blockPiece: boolean = false
	public unit: boolean = false
	public luxus: boolean = false
	public rarity: boolean = false
	public highlights: boolean = false
	public highlightNumber: number | null = null
	public priceProposal: number | null = null
	public price: number | null = null
	public priceMarket: number | null = null
	public pricePurchase: number | null = null
	public export: number | null = null
	public exportDate: string | null = null
	public deleted: number | null = null
	public deletedDate: string | null = null
	public type: number | null = null
	public sold: boolean | null = null
	public imageList: ImageList = new ImageList()
	public selected: boolean = false
	public _cache?: ProductSaleApi

	public async readFromApi(id: string, params?: apiGet.Request) {
		const response: ProductSaleApi | null = await apiGet.call(id, params)
		if (response) {
			this.mapFromApi(response)
		}
	}

	public mapFromApi(input: ProductSaleApi): void {
		this.id = input.id
		this.sortOrder = input.sortOrder
		this.idNext = input.idNext
		this.idPrevious = input.idPrevious
		this.number = input.number
		this.auctionId = input.auctionId
		this.collectionId = input.collectionId
		this.lotNr = input.lotNr
		this.setNr = input.setNr
		this.zNr = input.zNr
		this.zNrNumber = input.zNrNumber
		this.miNr = input.miNr
		this.nameDe = input.nameDe
		this.nameFr = input.nameFr
		this.year = input.year
		this.valueLevel = input.valueLevel
		this.valueLevelCurrency = input.valueLevelCurrency
		this.descriptionDe = input.descriptionDe
		this.descriptionFr = input.descriptionFr
		this.descriptionLongDe = input.descriptionLongDe
		this.descriptionLongFr = input.descriptionLongFr
		this.tagsDe = input.tagsDe
		this.tagsFr = input.tagsFr
		this.issueDate = input.issueDate
		this.userId = input.userId
		this.userNumber = input.userNumber
		this.categoryId = input.category
		this.stamped = input.stamped
		this.fullStamped = input.fullStamped
		this.letter = input.letter
		this.letterExcerpt = input.letterExcerpt
		this.firstday = input.firstday
		this.mintNeverHinged = input.mintNeverHinged
		this.unused = input.unused
		this.unusedWithoutRubber = input.unusedWithoutRubber
		this.blockOfFour = input.blockOfFour
		this.blockPiece = input.blockPiece
		this.unit = input.unit
		this.luxus = input.luxus
		this.rarity = input.rarity
		this.highlights = input.highlights
		this.highlightNumber = input.highlightNumber
		this.priceProposal = input.priceProposal
		this.price = input.price
		this.priceMarket = input.priceMarket
		this.pricePurchase = input.pricePurchase
		this.export = input.export
		this.exportDate = input.exportDate
		this.deleted = input.deleted
		this.deletedDate = input.deletedDate
		this.type = input.type
		this.sold = input.sold ? Boolean(input.sold) : null
		this.imageList.mapApiToThis(input.imageList)
		this.updateCache()
	}

	public updateCache(): void {
		this._cache = this.mapToApi()
	}

	public hasChanges(): boolean {
		const current: string = JSON.stringify(this.mapToApi())
		const cached: string = JSON.stringify(this._cache)
		return current !== cached
	}

	public mapFromProduct(input: {
		product: Product
		price?: number
		priceProposal?: number
		priceProposalKey?: string
	}) {
		this.categoryId = input.product.category
		this.sortOrder = input.product.sortOrder
		this.zNr = input.product.zNr
		this.zNrNumber = input.product.zNrNumber
		this.nameDe = input.product.titleDe
		this.year = input.product.year
		this.descriptionDe = input.product.additionalInfo10
		this.price = input.price ?? null
		this.adoptPriceProposal(input.priceProposal, input.priceProposalKey)
	}

	public getProductNumberString(): string {
		return this.userNumber + '.' + ('0000' + this.number).slice(-4)
	}

	public mapFromProductSetList(input: {
		productSetList: ProductSetList
		price?: number
		priceProposal?: number
		priceProposalKey?: string
	}) {
		this.categoryId = input.productSetList.getCategory()
		this.sortOrder = Number(input.productSetList.getSortOrder())
		this.zNr = input.productSetList.getZnr()
		this.zNrNumber = input.productSetList.getZnrNumber()
		this.nameDe = input.productSetList.getTitle()
		this.year = input.productSetList.getYear()
		this.descriptionDe = input.productSetList.getDescription()
		this.price = input.price ?? null
		this.adoptPriceProposal(input.priceProposal, input.priceProposalKey)
	}

	public mapToCheckboxElement(): CheckboxElement[] {
		return [
			{ key: 'stamped', label: 'Gestempelt', checked: this.stamped },
			{ key: 'fullStamped', label: 'Vollstempel', checked: this.fullStamped },
			{ key: 'letter', label: 'Brief', checked: this.letter },
			{ key: 'letterExcerpt', label: 'Briefausschnitt', checked: this.letterExcerpt },
			{ key: 'firstday', label: 'Ersttag', checked: this.firstday },
			{ key: 'mintNeverHinged', label: 'Postfrisch', checked: this.mintNeverHinged },
			{ key: 'unused', label: 'Ungebraucht', checked: this.unused },
			{
				key: 'unusedWithoutRubber',
				label: 'Ungebraucht ohne Gummi',
				checked: this.unusedWithoutRubber,
			},
			{ key: 'blockOfFour', label: 'Vierer-Block', checked: this.blockOfFour },
			{ key: 'unit', label: 'Einheit', checked: this.unit },
			{ key: 'luxus', label: 'Luxus', checked: this.luxus },
			{ key: 'rarity', label: 'Rarität', checked: this.rarity },
		]
	}

	public toggleCheck(key: CheckboxKeys): void {
		this[key] = !this[key]
	}

	private adoptPriceProposal(priceProposal?: number, key?: string): void {
		this.priceProposal = priceProposal ?? null
		this.setCheckboxesToFalse()
		switch (key) {
			case 'stamped':
				this.stamped = true
				break
			case 'mintNeverHinged':
				this.mintNeverHinged = true
				break
			case 'unstamped':
				this.unused = true
				break
			case 'letter':
				this.letter = true
				break
			case 'fdc':
				this.firstday = true
				break
			case 'blockUnstamped':
				this.stamped = true
				this.blockOfFour = true
				break
			case 'blockMintNeverHinged':
				this.mintNeverHinged = true
				this.blockOfFour = true
				break
			case 'fullstampFirstDay':
				this.fullStamped = true
				this.firstday = true
				break
			case 'firstDayBlock':
				this.blockOfFour = true
				this.firstday = true
				break
		}
	}

	public async writeToApi(): Promise<void> {
		await apiPut.call(this.id, this.mapToApi())
		this.updateCache()
	}

	public async deleteFromApi(): Promise<void> {
		await apiDelete.call(this.id)
	}

	public mapToApi(): ProductSaleApi {
		return {
			id: this.id,
			sortOrder: this.sortOrder,
			idNext: this.idNext,
			idPrevious: this.idPrevious,
			number: this.number,
			auctionId: this.auctionId,
			collectionId: this.collectionId,
			lotNr: this.lotNr,
			setNr: this.setNr,
			zNr: this.zNr,
			zNrNumber: this.zNrNumber,
			miNr: this.miNr,
			nameDe: this.nameDe,
			nameFr: this.nameFr,
			year: this.year,
			valueLevel: this.valueLevel,
			valueLevelCurrency: this.valueLevelCurrency,
			descriptionDe: this.descriptionDe,
			descriptionFr: this.descriptionFr,
			descriptionLongDe: this.descriptionLongDe,
			descriptionLongFr: this.descriptionLongFr,
			tagsDe: this.tagsDe,
			tagsFr: this.tagsFr,
			issueDate: this.issueDate,
			userId: this.userId,
			userNumber: this.userNumber,
			category: this.categoryId ? this.categoryId : null,
			stamped: this.stamped,
			fullStamped: this.fullStamped,
			letter: this.letter,
			letterExcerpt: this.letterExcerpt,
			firstday: this.firstday,
			mintNeverHinged: this.mintNeverHinged,
			unused: this.unused,
			unusedWithoutRubber: this.unusedWithoutRubber,
			blockOfFour: this.blockOfFour,
			blockPiece: this.blockPiece,
			unit: this.unit,
			luxus: this.luxus,
			rarity: this.rarity,
			highlights: this.highlights,
			highlightNumber: this.highlightNumber,
			priceProposal: this.priceProposal,
			price: this.price,
			priceMarket: this.priceMarket,
			pricePurchase: this.pricePurchase,
			export: this.export,
			exportDate: this.exportDate,
			deleted: this.deleted,
			deletedDate: this.deletedDate,
			type: this.type,
			sold: this.sold !== null ? Number(this.sold) : null,
			imageList: [],
		}
	}

	private setCheckboxesToFalse(): void {
		this.stamped = false
		this.fullStamped = false
		this.letter = false
		this.letterExcerpt = false
		this.firstday = false
		this.mintNeverHinged = false
		this.unused = false
		this.unusedWithoutRubber = false
		this.blockOfFour = false
		this.blockPiece = false
		this.unit = false
		this.luxus = false
		this.rarity = false
		this.highlights = false
	}
}
