import { Availability } from 'enums/Availability'
import { QueryParams } from 'functions/url'
import AdvancedSearchKey from 'interfaces/Search/AdvancedSearchKey'

export default class NullableAvailability implements AdvancedSearchKey {
	public id: string
	public value: Availability | null
	public defaultValue?: Availability

	constructor(id: string, defaultValue?: Availability) {
		this.id = id
		this.value = null
		this.defaultValue = defaultValue
	}

	public get(): Availability | null {
		if (this.value !== null) return this.value
		return this.defaultValue ?? null
	}

	public reset() {
		this.value = null
	}

	public change(input: Availability): void {
		if (input === Availability.SOLD) {
			switch (this.value) {
				case null:
					this.value = Availability.AVAILABLE
					break
				case Availability.AVAILABLE:
					this.value = null
					break
				case Availability.SOLD:
					this.value = Availability.AVAILABLE
					break
			}
		}
		if (input === Availability.AVAILABLE) {
			switch (this.value) {
				case null:
					this.value = Availability.SOLD
					break
				case Availability.AVAILABLE:
					this.value = Availability.SOLD
					break
				case Availability.SOLD:
					this.value = null
					break
			}
		}
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				this.value = value ? (String(value) as Availability) : null
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.value) {
			return this.id + '=' + this.value
		}
		return null
	}

	public mapToApi(): string | null {
		if (this.value === null && this.defaultValue) {
			return this.defaultValue
		}
		return this.value
	}
}
