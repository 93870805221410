import Search from 'pages/ProductSaleSearch/classes/Search'

interface Props {
	search: Search
	handleSearch: (resetPageIndex?: boolean) => Promise<void>
}

export default function SortOrderSelectComponent(props: Props) {
	function handleChange(e: React.ChangeEvent<HTMLSelectElement>): void {
		const amount: number = Number(e.target.value)
		switch (amount) {
			case 0:
				props.search.pageCount.value = null
				break
			case 12:
				props.search.pageCount.value = null
				break
			default:
				props.search.pageCount.value = amount
				break
		}

		props.handleSearch(true)
	}

	return (
		<select
			className="form-select form-select-sm"
			onChange={handleChange}
			style={{ maxWidth: '100px' }}
			value={props.search.pageCount.value ?? 0}
		>
			<option value={0}>Anzahl</option>
			{[12, 50, 100, 200].map((e: number, i: number) => {
				return (
					<option value={e} key={i}>
						{e}
					</option>
				)
			})}
		</select>
	)
}
