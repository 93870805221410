import axios from 'axios'

import { APIURL } from 'constants/urls'

export async function apiCall(input: RequestBody): Promise<ResponseData | null> {
	const url = APIURL + '/link-sale'
	try {
		const response: Response = await axios.post(url, input)
		return response.data.data
	} catch (e: unknown) {
		return null
	}
}

export type RequestBody = {
	productSaleId: number
	name: string
}

export interface Response {
	data: {
		data: ResponseData
		status: number
	}
}

export interface ResponseData {
	id: number
	position: number
}
