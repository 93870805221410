import UserApi from 'interfaces/UserApi'

export function getToken(): string | null {
	const token: string | null = localStorage.getItem('token')
	if (token) {
		return token
	}
	return null
}

export function setToken(tokens: string): void {
	localStorage.setItem('token', tokens)
}

export function parseJwt(token: string): UserApi {
	const base64Url: string = token.split('.')[1]
	const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
	)

	return JSON.parse(jsonPayload)
}

export function isUserAdmin(): boolean {
	const token: string | null = getToken()
	if (token) {
		const user: UserApi = parseJwt(token)
		if (user.isAdmin) {
			return true
		}
	}
	return false
}
