import { useEffect } from 'react'
import { useState } from 'react'

import UserList from 'classes/UserList'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import clone from 'functions/clone'
import UrlHook from 'hooks/Url'
import Search from 'pages/UserSearch/classes/Search'
import SearchComponent from 'pages/UserSearch/components/Search'
import UserListComponent from 'pages/UserSearch/components/UserList'

export default function UserSearchPage() {
	const [userList, setUserList] = useState<UserList>(new UserList())
	const [search, setSearch] = useState<Search>(new Search())
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [getUrl, setUrl] = UrlHook<Search>(search)

	useEffect(() => {
		initialize()
	}, [])

	async function initialize() {
		getUrl()
		await userList.initialize()
		setUserList(clone(userList))
		await handleSearch()
	}

	async function handleSearch(): Promise<void> {
		setIsLoading(true)
		userList.setSearched(search.searchTerm.value ?? '')
		setUserList(clone(userList))
		setUrl()
		setIsLoading(false)
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				<h3 className="mb-4">Alle Einlieferer</h3>
				<SearchComponent
					search={search}
					setSearch={setSearch}
					handleSearch={handleSearch}
				/>
				<hr />
				<UserListComponent userList={userList} setUserList={setUserList} />
			</div>
		</main>
	)
}
