import axios from 'axios'

import AuctionApi from 'interfaces/AuctionApi'

export async function call(id: number): Promise<AuctionApi | null> {
	try {
		const response: Response = await axios.get('/auction/' + id)
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Response = {
	data: AuctionApi
}
